import React from "react";

export default function ImageIconButton(props) {
  return (
    <button
      onClick={props.onClick}
      className="border border-[#70737D] rounded-3xl w-10 h-10 p-2 mt-4 mb-10 flex items-center justify-center"
    >
      <img src={props.src} alt="icon-button" />
    </button>
  );
}
