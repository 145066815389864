import React, { useState, useEffect } from "react";
import ImageIconButton from "../../../components/ImageIconButton";
import Heading from "../../../components/Heading";
import Title from "../../../components/Title";
import Text from "../../../components/Text";

import { getDCJobProfile } from "../../../api/services/multispeakerTranscribe";

export default function JobProfile(props) {
  const { userId } = props;
  const [dcJobProfileData, setDCJobProfileData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dcJobProfileData = await getDCJobProfile(userId);
        console.log("dcJobProfileData", dcJobProfileData);
        setDCJobProfileData(dcJobProfileData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="text-white px-6 py-8">
        <ImageIconButton
          src={require("../../../assets/icons/arrow_back.png")}
          onClick={() => props.setSelectedWidget(null)}
        />
        <Heading title="Job Profile" />
        {dcJobProfileData && (
          <div className="my-4 px-2">
            {dcJobProfileData.employee_company && (
              <>
                <Title color="secondary" label="Company Name" />
                <Text label={dcJobProfileData.employee_company} />
              </>
            )}
            {dcJobProfileData.employee_company &&
              dcJobProfileData.job_profile && <div className="my-4"></div>}
            {dcJobProfileData.job_profile && (
              <>
                <Title color="secondary" label="Job Profile" />
                <Text label={dcJobProfileData.job_profile} />
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
