import React from "react";
import BlobRecorder from "../../components/Record/BlobRecorder";

const ScenarioDetails = ({ audience, onNext }) => {
  const handleSubmit = async (blob) => {
    await onNext(blob);
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "trackMixpanelEvent",
        meta: { action: "CLICKED_GENERATE_AFTER_RECORDING" },
      })
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-screen h-screen px-8 pt-10 space-y-8">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-center space-x-4">
          <img
            className="h-6 w-6 mt-1 cursor-pointer"
            alt="cross"
            src={require("../../assets/icons/cross-outlined-grey.png")}
            onClick={() =>
              window.ReactNativeWebView.postMessage(
                JSON.stringify({ event: "navigateBack" })
              )
            }
          />
          <h1 className="text-2xl font-semibold text-darkgray font-sans">
            Customise your Playbook
          </h1>
        </div>
      </div>
      <div>
        <h2 className="text-xl font-semibold text-darkgray font-sans">
          {`Please elaborate the scenario you want to practice with your ${audience.toLowerCase()} in 4-5 sentences.`}
        </h2>
        <p className="text-darkBlue3 italic text-base mt-2 mb-6 leading-6">
          For example, I am in a meeting in the office and I'm providing
          feedback on the previous quarter's performance to a team member. I
          want to be assertive, yet empathetic.
        </p>
        <div className="px-2 my-4 items-start space-y-3">
          <div className="flex items-center space-x-2">
            <span className="text-darkgray px-1">•</span>
            <p className="w-full text-darkgray">
              Where's this conversation taking place?
            </p>
          </div>

          <div className="flex items-center space-x-2">
            <span className="text-darkgray px-1">•</span>
            <p className="w-full text-darkgray">What is the scenario about?</p>
          </div>

          <div className="flex items-center space-x-2">
            <span className="text-darkgray px-1">•</span>
            <p className="w-full text-darkgray">
              Is there anything specific you'd like to focus on? (optional)
            </p>
          </div>
        </div>
      </div>
      <div className="w-full p-10 absolute bottom-0 rounded-t-2xl bg-white">
        <BlobRecorder stopListening={handleSubmit} />
      </div>
    </div>
  );
};

export default ScenarioDetails;
