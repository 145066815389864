import React, { useState, useEffect } from "react";
import { getSuggestedSpeechData } from "../../api/services/ai";
import { getTranscriptionData } from "../../api/services/transcribe";
import { useNavigate } from "react-router-dom";
import NewMediaPlayer from "../../components/NewMediaPlayer";

export default function TranscriptionReview() {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("userId");
  const assessmentId = params.get("assessmentId");
  const flow = params.get("flow");
  const userType = params.get("userType") || params.get("usertype");

  const [suggestedSpeechData, setSuggestedSpeechData] = useState(null);
  const [suggestedSpeechUrl, setSuggestedSpeechUrl] = useState(null);
  const [transcriptionData, setTranscriptionData] = useState(null);
  const [error, setError] = useState(false); // State for tracking errors

  useEffect(() => {
    const fetchData = async () => {
      try {
        const suggestedData = await getSuggestedSpeechData(
          userId,
          assessmentId,
          userType
        );
        const originalData = await getTranscriptionData(
          userId,
          assessmentId,
          userType
        );
        setSuggestedSpeechData(suggestedData.suggested_speech);
        setSuggestedSpeechUrl(suggestedData.suggested_speech_url);
        setTranscriptionData(originalData.transcription);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError(true);
      }
    };

    fetchData();
  }, []);

  const handleNavigate = () => {
    const url = `/ai-assessment/?userId=${userId}&assessmentId=${assessmentId}&userType=${userType}&flow=${flow}`;
    navigate(url,{state:{indext:1}});
  };

  const handlePlay = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "trackMixpanelEvent",
        meta: { action: "SUGGESTED_SPEECH_VOICE" },
      })
    );
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-screen pt-10 space-y-8">
      <div className="flex items-center justify-between w-full px-8">
        <div className="relative flex align-middle justify-center w-full">
          <h1 className="text-2xl font-semibold text-darkgray">
            Transcription Review
          </h1>
          <img
            className="h-9 w-9 absolute left-0 cursor-pointer"
            alt="cross"
            src={require("../../assets/icons/arrow-left-outlined-grey.png")}
            onClick={handleNavigate}
          />
        </div>
      </div>
      {suggestedSpeechUrl && (
        <div className="w-full px-8">
          <NewMediaPlayer url={suggestedSpeechUrl} onPlay={handlePlay} />
        </div>
      )}
      {error ? (
        <div className="flex items-center justify-center w-full h-full">
          <p className="text-base text-darkgray w-2/3 text-center">
            There seems to be an issue in the section. You can report it at{" "}
            <a href="mailto:care@vani.coach" className="text-primary">
              care@vani.coach
            </a>
          </p>
        </div>
      ) : (
        <div
          className="flex-1 w-full flex flex-col space-y-4"
          style={{ height: "calc(100% - 10rem)" }}
        >
          <div
            className="flex-none overflow-auto mx-8 space-y-2 pb-4"
            style={{ height: "30%" }}
          >
            <div className="sticky top-0 bg-white z-10 py-2">
              <h1 className="uppercase text-xl font-bold text-darkgray tracking-wide">
                Original
              </h1>
            </div>
            <p className="text-base text-darkgray leading-7 font-sans">
              {transcriptionData}
            </p>
          </div>
          <div
            className="flex-none bg-green3 px-8 rounded-t-2xl overflow-auto space-y-2"
            style={{ height: "70%" }}
          >
            <div className="sticky top-0 bg-green3 z-10 py-2">
              <div className="flex space-x-2 items-center mt-8">
                <img
                  className="h-5 w-5"
                  alt="tick"
                  src={require("../../assets/icons/tick-filled-green.png")}
                />
                <h1 className="uppercase text-xl font-semibold text-darkgray tracking-wide leading-5">
                  Suggested
                </h1>
              </div>
            </div>
            <p className="text-base text-darkgray leading-7 font-sans">
              {suggestedSpeechData}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}
