import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { motion } from "framer-motion";
import * as Sentry from "@sentry/react";
import { jwtDecode } from "jwt-decode";

import AudienceSelection from "./CustomisePlaybook/AudienceSelection";
import JobProfileQuestion from "./CustomisePlaybook/JobProfileQuestion";
import ScenarioDetails from "./CustomisePlaybook/ScenarioDetails";
import TriviaScreen from "./CustomisePlaybook/TriviaScreen";
import SplashScreen from "./CustomisePlaybook/SplashScreen";

import { generateSituationsFromRoleplay } from "../api/services/roleplay";
import Loader from "../components/Loader";

const CustomisePlaybook = () => {
  const location = useLocation();
  const [userId, setUserId] = useState(null);
  const [step, setStep] = useState(1);
  const [audience, setAudience] = useState("");
  const [isWorkRelated, setIsWorkRelated] = useState(true);
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (token) {
      try {
        const decoded = jwtDecode(token);
        const userid =
          decoded["https://hasura.io/jwt/claims"]["x-hasura-user-id"];
        localStorage.setItem("token", token);
        localStorage.setItem("userid", userid);
        setUserId(userid);
      } catch (error) {
        setError("Something went wrong. Please try again later.");
        console.error("Invalid token", error);
      }
    } else {
      const storedUserId = localStorage.getItem("userid");
      if (storedUserId) {
        setUserId(storedUserId);
      } else {
        setError("Something went wrong. Please try again later.");
      }
    }
  }, [location]);

  if (error) {
    return (
      <div className="relative w-full h-screen flex justify-center items-center text-darkgray">
        <img
          className="absolute top-10 left-8 h-6 w-6 mt-1 cursor-pointer"
          alt="cross"
          src={require("../assets/icons/cross-outlined-grey.png")}
          onClick={() =>
            window.ReactNativeWebView.postMessage(
              JSON.stringify({ event: "navigateBack" })
            )
          }
        />
        {error}
      </div>
    );
  }

  if (!userId) {
    return <Loader />;
  }

  const handleNext = (audience, isOther) => {
    setAudience(audience);
    if (isOther) {
      setStep(2);
    } else {
      setStep(3);
    }
  };

  const handleJobProfileNext = (isWorkRelated) => {
    setIsWorkRelated(isWorkRelated);
    setStep(3);
  };

  const handleSituationsGeneration = async (blob) => {
    setStep(4);
    setLoading(true);
    try {
      const response = await generateSituationsFromRoleplay(
        userId,
        blob,
        "1",
        audience,
        isWorkRelated
      );
      if (response?.situations?.length) {
        setResponse(response);
      } else {
        const error = new Error("No situations generated from roleplay.");
        error.response = response;
        throw error;
      }
    } catch (error) {
      console.error(error.message);
      Sentry.captureException(error, {
        extra: { response: error.response },
      });
    } finally {
      setLoading(false);
      setStep(5);
    }
  };

  const handleLongerAPICallTime = () => {
    setStep(5);
  };

  const handleNavigation = (screenIndex) => {
    if (screenIndex === 0) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ event: "navigateToSelectSituation" })
      );
    } else if (screenIndex === 1) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({ event: "navigateToHome" })
      );
    } else if (screenIndex === 2) {
      setStep(3);
    }
  };

  const PageTransition = ({ children }) => {
    const location = useLocation();

    return (
      <motion.main
        className="main__container"
        key={location.pathname}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 2 }}
      >
        {children}
      </motion.main>
    );
  };

  const renderStep = () => {
    switch (step) {
      case 1:
        return <AudienceSelection onNext={handleNext} />;
      case 2:
        return <JobProfileQuestion onNext={handleJobProfileNext} />;
      case 3:
        return (
          <ScenarioDetails
            onNext={handleSituationsGeneration}
            audience={audience}
          />
        );
      case 4:
        return (
          <PageTransition>
            <TriviaScreen onNext={handleLongerAPICallTime} />
          </PageTransition>
        );
      case 5:
        return (
          <PageTransition>
            <SplashScreen
              response={response}
              loading={loading}
              onNext={handleNavigation}
            />
          </PageTransition>
        );
      default:
        return null;
    }
  };

  return <div>{renderStep()}</div>;
};

export default CustomisePlaybook;
