import React, { useState, useEffect } from "react";
import ImageIconButton from "../../../components/ImageIconButton";
import Heading from "../../../components/Heading";
import SubHeading from "../../../components/SubHeading";
import HighlightWordComponent from "../../../components/HighlightWordComponent";

import { getVocabularyData } from "../../../api/services/ai";

export default function Vocabulary(props) {
  const { userId, assessmentId, userType } = props;
  const [vocabularySpeechData, setVocabularySpeechData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const vocabularyData = await getVocabularyData(
          userId,
          assessmentId,
          userType
        );
        setVocabularySpeechData(vocabularyData.vocabulary_improvement);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="flex flex-col h-screen">
      <div className="flex-shrink-0 text-white px-6 py-8">
        <ImageIconButton
          src={require("../../../assets/icons/arrow_back.png")}
          onClick={() => props.setSelectedWidget(null)}
        />
        <Heading title="Enhanced Articulation" />
        {/* <SubHeading content="Amet minim mollit non deserunt ullamco " /> */}
      </div>
      <div className="flex-1 overflow-y-scroll px-6 pb-8">
        {vocabularySpeechData &&
          vocabularySpeechData.map((vocabularySpeech) => (
            <HighlightWordComponent data={vocabularySpeech} />
          ))}
      </div>
    </div>
  );
}
