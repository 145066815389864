import axiosInstance from "../axios";

const AI_ENDPOINT = "/api/ai";

export const getLandingPageData = async (userId, assessmentId, userType) => {
  try {
    const response = await axiosInstance.get(
      `${AI_ENDPOINT}/get-ai-landing-page-json`,
      {
        params: {
          userid: userId,
          usertype: userType,
          assessmentid: assessmentId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      "Error fetching sentence comparison data: " + error.message
    );
  }
};

export const getSentenceComparisonData = async (
  userId,
  assessmentId,
  userType
) => {
  try {
    const response = await axiosInstance.get(
      `${AI_ENDPOINT}/get-sentence-comparison-json`,
      {
        params: {
          userid: userId,
          usertype: userType,
          assessmentid: assessmentId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      "Error fetching sentence comparison data: " + error.message
    );
  }
};

export const getSuggestedSpeechData = async (
  userId,
  assessmentId,
  userType
) => {
  try {
    const response = await axiosInstance.get(
      `${AI_ENDPOINT}/get-suggested-speech-json`,
      {
        params: {
          userid: userId,
          usertype: userType,
          assessmentid: assessmentId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching suggested speech data: " + error.message);
  }
};

export const getVocabularyData = async (userId, assessmentId, userType) => {
  try {
    const response = await axiosInstance.get(
      `${AI_ENDPOINT}/get-vocabulary-json`,
      {
        params: {
          userid: userId,
          usertype: userType,
          assessmentid: assessmentId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching vocabulary data: " + error.message);
  }
};

export const getGrammarData = async (userId, assessmentId, userType) => {
  try {
    const response = await axiosInstance.get(
      `${AI_ENDPOINT}/get-grammer-json`,
      {
        params: {
          userid: userId,
          usertype: userType,
          assessmentid: assessmentId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching vocabulary data: " + error.message);
  }
};
