import React, { useState, useEffect } from "react";
import ImageIconButton from "../../../components/ImageIconButton";
import Heading from "../../../components/Heading";
import Title from "../../../components/Title";
import Text from "../../../components/Text";
import BottomSheet from "../../../components/BottomSheet";
import MediaPlayer from "../../../components/MediaPlayer";

import { getSuggestedSpeechData } from "../../../api/services/ai";
import { getTranscriptionData } from "../../../api/services/transcribe";

export default function SuggestedSpeech(props) {
  const { userId, assessmentId, userType } = props;
  const [expand, setExpand] = useState(false);
  const [suggestedSpeechData, setSuggestedSpeechData] = useState(null);
  const [suggestedSpeechUrl, setSuggestedSpeechUrl] = useState(null);
  const [transcriptionData, setTranscriptionData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const suggestedData = await getSuggestedSpeechData(
          userId,
          assessmentId,
          userType
        );
        const originalData = await getTranscriptionData(
          userId,
          assessmentId,
          userType
        );
        setSuggestedSpeechData(suggestedData.suggested_speech);
        setSuggestedSpeechUrl(suggestedData.suggested_speech_url);
        setTranscriptionData(originalData.transcription);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="text-white px-8 py-8">
        <ImageIconButton
          src={require("../../../assets/icons/arrow_back.png")}
          onClick={() => props.setSelectedWidget(null)}
        />
        <Heading title="Transcription Review" />
        {/* <SubHeading content="Amet minim mollit non deserunt ullamco " /> */}
        <div className="my-4">
          {suggestedSpeechUrl && <MediaPlayer url={suggestedSpeechUrl} />}
          <Title color="secondary" label="Suggested" />
          <div
            className={`mt-2 ${
              expand ? "h-[256px]" : "h-[512px]"
            } overflow-y-scroll`}
          >
            <Text label={suggestedSpeechData} />
          </div>
        </div>
      </div>
      <BottomSheet
        title="Original"
        content={transcriptionData}
        expand={expand}
        setExpand={setExpand}
      />{" "}
    </div>
  );
}
