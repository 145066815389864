import congratulations from "../assets/icons/congratulations.svg";

const Congratulation = () => {
  return (
    <div className="flex justify-center h-dvh w-full font-sans">
      <div className="h-full w-full border rounded-lg p-12 shadow-md flex flex-col justify-between">
        <div className="h-[75vh] w-full flex justify-center flex-col items-center">
          <div>
            <img
              src={congratulations}
              className="h-[15rem] w-auto rounded-lg mb-4"
              alt="Blub"
            />
          </div>
          <div className="text-darkgray font-[800] text-[2.1rem]">
            Welcome Aboard!
          </div>
          <div className="text-gray-600 text-[1.2rem] text-center mt-1">
            Your enrolment is confirmed. Look out for an email with all the
            details you’ll need to get started.
          </div>
        </div>
      </div>
    </div>
  );
};

export default Congratulation;
