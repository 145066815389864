import React, { useState, useEffect } from "react";
import toast, { Toaster } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import vaniLogo from "../assets/icons/vani-logo-full.svg";
import tickIcon from "../assets/icons/tick-white-filled-green.svg";
import InternationPhoneInput from "../components/InternationPhoneInput";
import { PhoneNumberUtil } from "google-libphonenumber";

const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

// Utility to decode base64
const decodeBase64 = (str) => {
  try {
    return JSON.parse(atob(str));
  } catch (e) {
    console.error("Error decoding base64:", e);
    return null;
  }
};

export default function ContactVerification() {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [mpin, setMpin] = useState("");
  const [learnerId, setLearnerId] = useState(null);
  const [isSameAsPhone, setIsSameAsPhone] = useState(true);
  const [isPhoneValidState, setIsPhoneValidState] = useState(false);
  const [isWhatsappValidState, setIsWhatsappValidState] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const encodedData = params.get("data");

    if (encodedData) {
      const decodedData = decodeBase64(encodedData);

      console.log("decodedData", decodedData);

      if (decodedData) {
        setName(decodedData.name || "");
        const phoneNumber = decodedData.phone?.replace(/\s/g, "+") || "";
        const whatsappNumber = decodedData.whatsapp || "";
        setPhone(phoneNumber);
        setWhatsapp(whatsappNumber);
        setMpin(decodedData.mpin || "");
        setLearnerId(decodedData.learner_id || null);
        setIsSameAsPhone(phoneNumber === whatsappNumber);

        setIsPhoneValidState(isPhoneValid(phoneNumber));
        setIsWhatsappValidState(isPhoneValid(whatsappNumber));
      } else {
        toast.error("Invalid data in the URL.");
      }
    } else {
      toast.error("No data found in the URL.");
    }
  }, []);

  const handlePhoneChange = (value) => {
    setPhone(value);
    setIsPhoneValidState(isPhoneValid(value));
    if (value !== whatsapp) {
      setIsSameAsPhone(false);
    }
  };

  const handleWhatsappChange = (value) => {
    setWhatsapp(value);
    setIsWhatsappValidState(isPhoneValid(value));
    setIsSameAsPhone(value === phone);
  };

  const handleRadioClick = () => {
    if (!isSameAsPhone) {
      setWhatsapp(phone);
      setIsSameAsPhone(true);
      setIsWhatsappValidState(isPhoneValid(phone));
    }
  };

  const handleSubmit = () => {
    const data = {
      learner_id: learnerId,
      phone: phone,
      whatsapp: whatsapp,
      mpin: mpin,
    };

    fetch(`${process.env.REACT_APP_THOR_URL}/jobs/learner_details_updated`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then(() => {
        navigate("/contact-verified");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("Failed to update details. Please try again.");
      });
  };

  return (
    <div className="flex flex-col items-center justify-start w-full h-dvh px-8 pt-8 py-10 space-y-10 font-sans">
      <Toaster position="bottom-center" />
      <div className="flex items-center justify-between w-full">
        <div className="relative flex align-middle justify-center w-full">
          <img src={vaniLogo} alt="vani-logo" />
        </div>
      </div>
      <div className="flex flex-col w-full space-y-6">
        <div className="flex flex-col w-full space-y-1">
          <div className="text-orange1 font-bold text-2xl">
            Welcome to Vani!
          </div>
          <div className="text-darkgray text-base">
            Please verify your contact details to ensure you receive all the
            program updates.
          </div>
        </div>
        <div className="flex flex-col w-full space-y-2">
          <div className="text-darkBlue2 font-bold text-base">Name</div>
          <div className="relative inline-block">
            <input
              className="h-[48px] px-4 w-full bg-grey2 text-darkBlue2 focus:outline-none rounded"
              type="text"
              placeholder="name"
              disabled
              value={name}
            />
            <img
              className="absolute right-4 top-1/2 transform -translate-y-1/2"
              src={tickIcon}
              alt="tick"
            />
          </div>
        </div>
        <div className="relative flex flex-col w-full space-y-2">
          <div className="text-darkBlue2 font-bold text-base">Phone No.</div>
          <InternationPhoneInput value={phone} onChange={handlePhoneChange} />
        </div>
        <div className="relative flex flex-col w-full space-y-2">
          <div className="text-darkBlue2 font-bold text-base">WhatsApp No.</div>
          <InternationPhoneInput
            value={whatsapp}
            onChange={handleWhatsappChange}
          />
          <div className="pt-1 px-3 flex space-x-2 items-center">
            <input
              className="h-5 w-5 accent-green1"
              type="radio"
              checked={isSameAsPhone}
              onChange={handleRadioClick}
            />
            <div className="text-darkBlue3 text-sm">Same as Phone No.</div>
          </div>
        </div>
      </div>
      <div className="flex justify-center absolute left-0 right-0 bottom-[32px] font-sans w-full px-10">
        <button
          className={`tracking-wider w-full bg-orange1 min-h-14 text-white text-base font-sans font-semibold py-4 px-6 rounded-full flex justify-center items-center ${
            isPhoneValidState && isWhatsappValidState
              ? ""
              : "opacity-50 cursor-not-allowed"
          }`}
          onClick={handleSubmit}
          disabled={!isPhoneValidState || !isWhatsappValidState}
        >
          Submit
        </button>
      </div>
    </div>
  );
}
