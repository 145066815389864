import React from "react";

export default function Text(props) {
  const colorVariants = {
    secondary: "text-secondary",
    primary: "text-primary",
    darkgray: "text-darkgray",
    lightgray: "text-lightgray",
    white: "text-white",
  };
  const fontVariants = {
    medium: "font-medium",
    semibold: "font-semibold",
    bold: "font-bold",
  };
  const alignVariants = {
    right: "text-right",
    center: "text-center",
  };

  const renderLabelWithStyles = () => {
    if (!props.label) {
      return null;
    }

    const words = props.label.split(" ");
    return words.map((word, index) => {
      let styleClasses = "";
      if (word.includes(props.underlineWord)) {
        styleClasses += " underline";
      }
      if (word.includes(props.boldWord)) {
        styleClasses += ` ${colorVariants.secondary} ${fontVariants.semibold}`;
      }

      return (
        <span key={index} className={styleClasses.trim()}>
          {word}
          {index < words.length - 1 ? " " : ""}
        </span>
      );
    });
  };

  return (
    <div
      className={`font-sans text-base font-normal leading-6 ${
        colorVariants[props.color]
      } ${fontVariants[props.bold]} ${alignVariants[props.align]}`}
    >
      {renderLabelWithStyles()}
    </div>
  );
}
