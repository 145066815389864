import React from "react";
import Text from "./Text";

function GrammerCorrect({ data }) {
  return (
    <div className="my-6">
      <div className="bg-[#171C29] rounded py-4 flex items-center">
        <div className="w-1/5 flex justify-center">
          <img
            src={require("../assets/icons/info.png")}
            alt="speaker"
            className="p-0.5"
            style={{ width: "24px", height: "24px" }}
          />
        </div>
        <div className="w-4/5 pr-4">
          <Text color="white" label={data.reason} />
        </div>
      </div>
      <div className="bg-lightgray rounded py-4 flex items-center">
        <div className="w-1/5 flex justify-center">
          <img
            src={require("../assets/icons/cross-outline.png")}
            alt="speaker"
            style={{ width: "24px", height: "24px" }}
          />
        </div>
        <div className="w-4/5 pr-4">
          <Text color="darkgray" label={data.original} />
        </div>
      </div>
      <div className="bg-secondary rounded flex items-center py-4">
        <div className="w-1/5 flex justify-center">
          <img
            src={require("../assets/icons/tick.png")}
            alt="speaker"
            className="bg-black p-1 rounded-full"
            style={{ width: "24px", height: "24px" }}
          />
        </div>
        <div className="w-4/5 pr-4">
          <Text color="darkgray" label={data.corrected} />
        </div>
      </div>
    </div>
  );
}

export default GrammerCorrect;
