import React, { useEffect } from "react";
import vaniLogo from "../assets/icons/vani-logo-full.svg";
import { Toaster } from "react-hot-toast";

// Utility to decode base64
const decodeBase64 = (str) => {
  try {
    return JSON.parse(atob(str));
  } catch (e) {
    console.error("Error decoding base64:", e);
    return null;
  }
};

export default function ContactVerified() {
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const encodedData = params.get("data");

    if (encodedData) {
      const decodedData = decodeBase64(encodedData);

      if (decodedData) {
        const learnerId = decodedData.learner_id || "";
        const phone = decodedData.phone?.replace(/\s/g, "+") || "";
        const whatsapp = decodedData.whatsapp || "";
        const name = decodedData.name || "";
        const mpin = decodedData.mpin || "";

        // Check if all fields are present
        if (learnerId && phone && whatsapp && name && mpin) {
          const data = {
            learner_id: learnerId,
            phone: phone,
            whatsapp: whatsapp,
            mpin: mpin,
          };

          fetch(
            `${process.env.REACT_APP_THOR_URL}/jobs/learner_details_updated`,
            {
              method: "POST",
              headers: {
                "Content-Type": "application/json",
              },
              body: JSON.stringify(data),
            }
          ).catch((error) => {
            console.error("Error:", error);
          });
        } else {
          console.error("Missing one or more fields in the decoded data.");
        }
      } else {
        console.error("Invalid base64 data in URL.");
      }
    }
  }, []);

  return (
    <div className="flex flex-col items-center justify-center w-full h-dvh px-8 pt-8 space-y-6 font-sans">
      <Toaster position="bottom-center" />
      <img src={vaniLogo} alt="vani-logo" />
      <div className="text-orange1 font-bold text-2xl">
        Thank you for your confirmation!
      </div>
    </div>
  );
}
