import { useState, useEffect, useRef } from "react";
import StartRecording from "../../assets/icons/start-recording.png";
import StopRecording from "../../assets/animations/record.gif";
import WhiteArrowRight from "../../assets/icons/arrow-right-white.png";
import ResetRecordingIcon from "../../assets/icons/retry-recording.png";
import { toast } from "react-toastify";

let interval;

const BlobRecorder = ({ stopListening }) => {
  const [seconds, setSeconds] = useState(0);
  const mediaRecorder = useRef();
  const [isRecording, setIsRecording] = useState(false);
  const [stream, setStream] = useState();
  const [, setAudioChunks] = useState([]);
  const [blob, setBlob] = useState();

  useEffect(() => {
    setUpAudio();

    return stopTimer;
  }, []);

  const setUpAudio = async () => {
    if ("MediaRecorder" in window) {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setStream(mediaStream);
      } catch (err) {
        console.error("Error accessing microphone:", err);
      }
    } else {
      // alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const startTimer = () => {
    interval = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 1000);
  };

  const stopTimer = () => {
    clearInterval(interval);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const startRecording = async () => {
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });

      if (stream) {
        const localAudioChunks = [];
        const media = new MediaRecorder(stream);

        setBlob(undefined);
        setSeconds(0);

        media.ondataavailable = (event) => {
          if (event.data.size > 0) {
            localAudioChunks.push(event.data);
          }
        };

        media.onstop = async () => {
          const audioBlob = new Blob(localAudioChunks, { type: "audio/wav" });
          setBlob(audioBlob);
          setAudioChunks([]);
        };

        mediaRecorder.current = media;
        mediaRecorder.current.start(1000);
        setIsRecording(true);
        startTimer();
      } else {
        console.log("no stream");
      }
    } catch (error) {
      toast.error(
        "Seems like you have denied permissions for accessing microphone. Please enable it to continue",
        {
          position: "bottom-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: undefined,
          theme: "dark",
        }
      );
    }
  };

  const stopRecording = () => {
    stopTimer();
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setIsRecording(false);
    }
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "trackMixpanelEvent",
        meta: { action: "STOP_RECORDING_GENERATE_SCENARIO" },
      })
    );
  };

  return (
    <div className="flex justify-center w-full">
      {isRecording && (
        <div
          className="rounded-2xl text-darkBlue2 text-center p-2 flex flex-col items-center space-y-2"
          onClick={stopRecording}
        >
          <img src={StopRecording} className="w-24" alt="stop-recording" />
          <p className="text-darkBlue2 font-semibold text-sm">
            {formatTime(seconds)}
          </p>
        </div>
      )}
      {!isRecording && seconds === 0 && (
        <div
          className="rounded-2xl text-darkBlue2 text-center p-2 flex flex-col items-center space-y-2"
          onClick={() => {
            startRecording();
            window.ReactNativeWebView.postMessage(
              JSON.stringify({
                event: "trackMixpanelEvent",
                meta: { action: "START_RECORDING_GENERATE_SCENARIO" },
              })
            );
          }}
        >
          <img src={StartRecording} className="w-24" alt="start-recording" />
          <p className="text-darkBlue2 text-sm">Start Recording</p>
        </div>
      )}
      {!isRecording && seconds > 0 && (
        <div className="flex items-center space-x-4">
          <div
            className="flex flex-col items-center"
            onClick={() => {
              startRecording();
              window.ReactNativeWebView.postMessage(
                JSON.stringify({
                  event: "trackMixpanelEvent",
                  meta: { action: "RETRY_RECORDING_GENERATE_SCENARIO" },
                })
              );
            }}
          >
            <img
              src={ResetRecordingIcon}
              className="w-12"
              alt="reset-recording"
            />
          </div>

          <div
            className="rounded-full bg-orange1 text-darkgray text-center h-12 flex flex-col justify-center items-center space-y-2"
            onClick={() => {
              stopListening(blob);
              setSeconds(0);
            }}
          >
            <p className="flex items-center text-sm font-bold text-white px-12 tracking-wider">
              Create{" "}
              <img
                src={WhiteArrowRight}
                className="w-6 pl-1"
                alt="right-arrow"
              />
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlobRecorder;
