import React from "react";
import Wave from "react-wavify";

export default function Waves() {
  return (
    <div className="absolute bottom-0 w-full -z-10 overflow-visible">
      <Wave
        fill="rgba(238, 238, 238, 0.4)"
        paused={false}
        style={{ position: "absolute", bottom: 0, zIndex: 1, height: 240 }}
        options={{
          amplitude: 120,
          speed: 0.2,
          points: 2,
        }}
      />
      <Wave
        fill="rgba(238, 238, 238, 0.4)"
        paused={false}
        style={{ position: "absolute", bottom: 0, zIndex: 2, height: 240 }}
        options={{
          amplitude: 100,
          speed: 0.25,
          points: 3,
        }}
      />
      <Wave
        fill="rgba(238, 238, 238, 0.4)"
        paused={false}
        style={{ position: "absolute", bottom: 0, zIndex: 3, height: 240 }}
        options={{
          height: 40,
          amplitude: 55,
          speed: 0.3,
          points: 4,
        }}
      />
    </div>
  );
}
