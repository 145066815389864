import loaderAnimation from "../assets/animations/loader.gif";

const Loader = () => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
      <img src={loaderAnimation} alt="Loader" />
    </div>
  );
};

export default Loader;
