import mic from "../assets/icons/mic.svg";
import { useNavigate } from "react-router-dom";

const AiFeedback = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const assessment_id = localStorage.getItem("assessmentId");
  const email_id = localStorage.getItem("email");
  const type = localStorage.getItem("flow");

  const handleContinue = () => {
    navigate(
      `/ai-assessment?userId=${userId}&assessmentId=${assessment_id}&userType=${type}&flow=${type}&email=${email_id}`
    );
  };

  return (
    <div className="flex justify-center h-dvh w-full font-sans">
      <div className="h-full w-full rounded-lg p-16 flex flex-col justify-between">
        <div className="h-[75vh] w-full flex justify-center flex-col items-center">
          <div>
            <img src={mic} className="h-[9rem] w-auto rounded-lg" alt="Mic" />
          </div>
          <div className="text-orange1 font-[700] text-[1.25rem] mt-8 mb-2">
            Step 3
          </div>
          <div className="text-darkgray font-[800] text-[2rem]">
            AI Feedback
          </div>
          <div className="text-gray-600 text-[1.2rem] text-center mt-1">
            Here is your detailed Al feedback to improve your next practice.
          </div>
        </div>
        <div className="flex justify-center absolute left-0 right-0 bottom-[32px] font-sans w-full px-10">
          <button
            className="tracking-wider w-full bg-orange1 min-h-14 text-white text-base font-sans font-semibold py-4 px-6 rounded-full flex justify-center items-center"
            onClick={handleContinue}
          >
            I Understand{" "}
            <img
              alt="right-arrow"
              src={require("../assets/icons/arrow-right-white.png")}
              className="w-5 h-5 ml-2 contain"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default AiFeedback;
