import axiosInstance from "../axios";

const TRANSCRIBE_ENDPOINT = "/api/transcribe";

export const getTranscriptionData = async (userId, assessmentId, userType) => {
  try {
    const response = await axiosInstance.get(
      `${TRANSCRIBE_ENDPOINT}/get-transcription`,
      {
        params: {
          userid: userId,
          usertype: userType,
          assessmentid: assessmentId,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching transcription data: " + error.message);
  }
};
