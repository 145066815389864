import React, { useState, useEffect } from "react";
import ImageIconButton from "../../../components/ImageIconButton";
import Heading from "../../../components/Heading";
import Title from "../../../components/Title";
import Text from "../../../components/Text";

import { getDCJobScenarioAudience } from "../../../api/services/multispeakerTranscribe";

function Audiences(props) {
  const { userId } = props;
  const [dCJobScenarioAudienceData, setDCJobScenarioAudienceData] =
    useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dCJobScenarioAudienceData = await getDCJobScenarioAudience(
          userId
        );
        setDCJobScenarioAudienceData(dCJobScenarioAudienceData.audiences);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="text-white px-6 py-8">
        <ImageIconButton
          src={require("../../../assets/icons/arrow_back.png")}
          onClick={() => props.setSelectedWidget(null)}
        />
        <Heading title="Audiences" />
        {dCJobScenarioAudienceData && (
          <div className="my-8 px-2">
            <ul>
              {dCJobScenarioAudienceData.map((audience, index) => (
                <li key={index}>
                  <div className="bg-[#171C29] px-8 py-4 border-b-[1px] border-[#70737D]">
                    <Text color="lightgray" label={audience} />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default Audiences;
