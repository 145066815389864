import React, { useEffect, useState } from "react";

const trivias = [
  "Practice helps you build muscle memory, making your responses more natural.",
  "Every practice session is a step forward, creating a ripple of improvement.",
];

const TriviaScreen = ({ onNext }) => {
  const [triviaIndex, setTriviaIndex] = useState(0);
  const [showInitialMessages, setShowInitialMessages] = useState(true);

  useEffect(() => {
    const initialTimeoutId = setTimeout(() => {
      setShowInitialMessages(false);

      const intervalId = setInterval(() => {
        setTriviaIndex((prevIndex) => (prevIndex + 1) % trivias.length);
      }, 5000);

      const totalTimeoutId = setTimeout(() => {
        clearInterval(intervalId);
        onNext();
      }, 10000 - 3000);

      return () => {
        clearInterval(intervalId);
        clearTimeout(totalTimeoutId);
      };
    }, 3000);

    return () => {
      clearTimeout(initialTimeoutId);
    };
  }, [onNext]);

  return (
    <div className="flex flex-col items-center justify-center h-screen px-10 space-y-4">
      <div className="flex flex-col items-center justify-center">
        <img
          src={require("../../assets/animations/meditate.gif")}
          alt="Loading"
        />
        {showInitialMessages ? (
          <>
            <p className="text-darkgray tracking-wider font-sans font-semibold text-xl">
              Hold Tight!
            </p>
            <p className="text-darkBlue3 font-sans text-base mt-0.5">
              Finalising your scenarios...
            </p>
          </>
        ) : (
          <p
            className="font-sans text-center text-base text-darkgray"
            dangerouslySetInnerHTML={{ __html: trivias[triviaIndex] }}
          />
        )}
      </div>
    </div>
  );
};

export default TriviaScreen;
