import React from "react";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

export default function MediaPlayer(props) {
  const customStyles = `
    .rhap_container {
      font-family: 'PT Sans';
    }
    .rhap_time {
      color: #EEEEEE;
    }
    .rhap_progress-bar {
      height: 3px;
    }
    .rhap_progress-filled {
      background-color: #F05A30;
    }
    .rhap_progress-indicator {
      background-color: #EEEEEE;
    }
  `;

  return (
    <div className="my-4">
      <style>{customStyles}</style>
      <AudioPlayer
        src={props.url}
        layout="horizontal-reverse"
        showJumpControls={false}
        customIcons={{
          play: (
            <img className="w-5" src={require("../assets/icons/play.png")} />
          ),
          pause: (
            <img className="w-5" src={require("../assets/icons/pause.png")} />
          ),
        }}
        customVolumeControls={[]}
        customAdditionalControls={[]}
        customProgressBarSection={[
          RHAP_UI.CURRENT_TIME,
          RHAP_UI.PROGRESS_BAR,
          RHAP_UI.DURATION,
          <div className="w-8"></div>,
        ]}
        progressBarSectionStyles={{ color: "blue" }}
        hasDefaultKeyBindings={false}
        style={{ backgroundColor: "#242834" }}
      />
    </div>
  );
}
