import React, { useEffect, useState } from "react";
import { getDCJobScenarioAudience } from "../../api/services/multispeakerTranscribe";

const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const defaultAudiences = [
  "Stakeholders",
  "Manager",
  "Team",
  "Vendors/Suppliers",
  "Audience at large",
];

export default function AudienceSelection({ onNext }) {
  const userId = localStorage.getItem("userid");

  const [audiences, setAudiences] = useState([]);
  const [selectedAudience, setSelectedAudience] = useState("");
  const [otherAudience, setOtherAudience] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleRadioChange = (event) => {
    setSelectedAudience(event.target.value);
  };

  const handleSubmit = () => {
    const audience =
      selectedAudience === "Other (can be outside job profile)"
        ? otherAudience
        : selectedAudience;
    if (
      selectedAudience === "Other (can be outside job profile)" &&
      otherAudience.trim() === ""
    ) {
      alert("Please mention the audience in Others");
    } else {
      onNext(
        audience,
        selectedAudience === "Other (can be outside job profile)"
      );
    }
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "trackMixpanelEvent",
        meta: { action: "PROFILE_SELECTED_CONTINUE" },
      })
    );
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dCJobScenarioAudienceData = await getDCJobScenarioAudience(
          userId
        );
        const learnersAudiences =
          dCJobScenarioAudienceData?.audiences[0]?.split(",") ||
          defaultAudiences;
        setAudiences([
          ...learnersAudiences,
          "Other (can be outside job profile)",
        ]);
      } catch (error) {
        setAudiences([
          ...defaultAudiences,
          "Other (can be outside job profile)",
        ]);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    if (selectedAudience === "Other (can be outside job profile)") {
      setIsButtonDisabled(otherAudience.trim() === "");
    } else {
      setIsButtonDisabled(selectedAudience === "");
    }
  }, [selectedAudience, otherAudience]);

  return (
    <div className="flex flex-col items-center justify-start w-screen h-screen px-8 pt-10 space-y-8">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-center space-x-4">
          <img
            className="h-6 w-6 mt-1 cursor-pointer"
            alt="cross"
            src={require("../../assets/icons/cross-outlined-grey.png")}
            onClick={() =>
              window.ReactNativeWebView.postMessage(
                JSON.stringify({ event: "navigateBack" })
              )
            }
          />
          <h1 className="text-2xl font-semibold text-darkgray font-sans">
            Customise your Playbook
          </h1>
        </div>
      </div>
      <div>
        <h2 className="text-xl font-semibold text-darkgray font-sans">
          In the new scenario, who is your listener or audience?
        </h2>
        <div className="px-2 my-4 items-start space-y-4">
          {audiences.map((audience) => (
            <div className="flex items-center space-x-4" key={audience}>
              <div className="flex-[0.1] flex items-center justify-center">
                <input
                  className="h-5 w-5 accent-green1"
                  type="radio"
                  value={audience}
                  checked={selectedAudience === audience}
                  onChange={handleRadioChange}
                />
              </div>
              <p className="flex-[0.9] text-base text-darkgray font-sans">
                {capitalizeFirstLetter(audience.trim())}
              </p>
            </div>
          ))}
          {selectedAudience === "Other (can be outside job profile)" && (
            <input
              type="text"
              className="border-b border-grey1 py-2 w-full focus:outline-none placeholder:italic"
              placeholder="For example: Family, Friends"
              value={otherAudience}
              onChange={(e) => setOtherAudience(e.target.value)}
            />
          )}
        </div>
      </div>
      <div className="w-full p-8 absolute bottom-0 rounded-t-2xl bg-white">
        <button
          className={`flex justify-center text-white font-semibold py-4 px-4 rounded-full w-full tracking-wider ${
            isButtonDisabled ? "bg-[#82858F]" : "bg-primary"
          }`}
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          Continue
        </button>
      </div>
    </div>
  );
}
