import React from "react";
import Text from "./Text";
import useTextToSpeech from "../hooks/useTextToSpeech";

function SentenceCompare({ data }) {
  const { speakText, speaking, cancel } = useTextToSpeech();

  const handleSpeaking = () => {
    if (speaking) cancel();
    else speakText(data.suggested);
  };

  return (
    <div className="my-6">
      <div className="bg-[#171C29] rounded py-4 flex items-center">
        <div className="w-1/5 flex justify-center">
          <img
            src={require("../assets/icons/info.png")}
            alt="speaker"
            style={{ width: "24px", height: "24px" }}
          />
        </div>
        <div className="w-4/5 pr-4">
          <Text color="white" label={data.original} />
        </div>
      </div>
      <div className="bg-secondary rounded flex justify-between items-center px-6 py-4">
        <div className="w-4/5">
          <Text color="darkgray" label={data.suggested} />
        </div>
        <div className="w-1/5 h-1/5 flex justify-center">
          <div
            className="ml-2 p-3 bg-darkgray rounded-3xl"
            // onClick={handleSpeaking}
          >
            {speaking ? (
              <img
                src={require("../assets/icons/pause-light.png")}
                alt="arrow_right"
                key="arrow_right"
                style={{ width: "18px", height: "18px" }}
              />
            ) : (
              <img
                src={require("../assets/icons/tick.png")}
                alt="speaker"
                key="speaker"
                className="bg-black"
                style={{ width: "10px", height: "10px" }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SentenceCompare;
