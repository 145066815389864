import React from "react";

import WhiteArrowRight from "../assets/icons/arrow-right-white.png";
import Learning_workshop from "../assets/icons/learning_workshop.png";
import p_coaching from "../assets/icons/Personalized_coaching.png";

import backarrow from "../assets/icons/arrow-left-outlined-grey.png";
import { useNavigate } from "react-router-dom";

const ProgramOverview = () => {
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const assessment_id = localStorage.getItem("assessmentId");
  const email_id = localStorage.getItem("email");
  const type = localStorage.getItem("flow");

  const handleClick = () => {
    navigate(
      `/ai-assessment?userId=${userId}&assessmentId=${assessment_id}&userType=${type}&flow=${type}&email=${email_id}`
    );
  };

  const handleClickEnrollNow = async () => {
    const email = localStorage.getItem("email");
    console.log(email);

    const leadData = {
      email: email,
      flag: "u",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_THOR_UR}add_leads_data`,
        {
          method: "POST",
          headers: {
            accept: "application/json, text/plain, */*",
            "content-type": "application/json",
          },
          body: JSON.stringify(leadData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("API Response:", data);
    } catch (error) {
      console.error("Error:", error);
      // setError(error);
    }

    navigate("/congratulations");
  };

  return (
    <div className="flex justify-center h-dvh w-full font-sans">
      <div className="h-full w-full rounded-lg p-7 flex flex-col ">
        <div className="relative flex align-middle justify-center w-full mt-2 mb-12">
          <div className="text-center font-[700] text-[20px] text-darkgray">
            What's in Store for You
          </div>
          <img
            alt="back-arrow"
            src={backarrow}
            className="h-8 w-8 absolute left-0"
            onClick={handleClick}
          />
        </div>

        <div className="">
          {/* <div className="mb-5">
            A 4-month communication fitness program to improve your confidence and job performance.
          </div> */}
          <div>
            <div className="flex w-full mb-4">
              <div className="w-1/5 pt-1">
                <img
                  src={p_coaching}
                  alt="AI Illustration"
                  className="h-12 w-auto rounded-lg"
                />
              </div>
              <div className="w-4/5">
                <div className="font-[600] mb-1 text-[17.5px] text-darkgray">
                  Personalised Coaching
                </div>
                <div className="text-gray-500">
                  Get detailed feedback on your practices from your personal
                  coach & join her for one on one coaching sessions.
                </div>
              </div>
            </div>

            <div className="flex w-full mb-4">
              <div className="w-1/5 pt-1">
                <img
                  src={Learning_workshop}
                  alt="AI Illustration"
                  className="h-12 w-auto rounded-lg"
                />
              </div>
              <div className="w-4/5">
                <div className="font-[600] mb-1 text-[17.5px] text-darkgray">
                  Learning Workshops
                </div>
                <div className="text-gray-500">
                  Be a part of fun & interactive live sessions by our expert
                  coaches & take your communication skills one step further.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center absolute left-0 right-0 bottom-[32px] font-sans w-full px-10">
          <button
            className="tracking-wider w-full bg-orange1 min-h-14 text-white text-base font-sans font-semibold py-2 px-6 rounded-full flex justify-center items-center"
            onClick={handleClickEnrollNow}
          >
            Enroll Now{" "}
            <img
              alt="arrow-right"
              src={WhiteArrowRight}
              className="w-5 h-5 ml-2 contain"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProgramOverview;
