import axiosInstance from "../axios";

const ROLEPLAY_ENDPOINT = "/api/roleplay";

export const startRoleplay = async (payload) => {
  try {
    const response = await axiosInstance.post(
      `${ROLEPLAY_ENDPOINT}/start`,
      payload
    );
    return response.data;
  } catch (error) {
    throw new Error("Error starting roleplay: " + error.message);
  }
};

export const nextRoleplay = async (id, audioBlob) => {
  try {
    const formData = new FormData();
    formData.append("id", id);
    formData.append("audio", audioBlob);

    const response = await axiosInstance.post(
      `${ROLEPLAY_ENDPOINT}/next`,
      formData
    );

    return response.data;
  } catch (error) {
    throw new Error("Error getting next roleplay: " + error.message);
  }
};

export const getRoleplaySituations = async () => {
  try {
    const response = await axiosInstance.post(
      `${ROLEPLAY_ENDPOINT}/get-list-of-roleplay-situation`
    );
    return response.data;
  } catch (error) {
    throw new Error(
      "Error fetching list of roleplay situations: " + error.message
    );
  }
};

export const generateSituationsFromRoleplay = async (
  userid,
  audioBlob,
  batchid,
  audience,
  isworkrelated
) => {
  try {
    const formData = new FormData();
    formData.append("userid", userid);
    formData.append("audio", audioBlob);
    formData.append("batchid", batchid);
    formData.append("audience", audience);
    formData.append("isworkrelated", isworkrelated);

    const response = await axiosInstance.post(
      `${ROLEPLAY_ENDPOINT}/generate-situations-from-roleplay`,
      formData
    );

    return response.data;
  } catch (error) {
    throw new Error("Error getting next roleplay: " + error.message);
  }
};
