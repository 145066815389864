import React, { useState, useEffect, useRef } from "react";

const AudioRecorder = ({ stopListening }) => {
  const mediaRecorder = useRef(null);
  const [isRecording, setIsRecording] = useState(false);
  const [stream, setStream] = useState(null);
  const [, setAudioChunks] = useState([]);

  useEffect(() => {
    setUpAudio();
  }, []);

  const setUpAudio = async () => {
    if ("MediaRecorder" in window) {
      try {
        const mediaStream = await navigator.mediaDevices.getUserMedia({
          audio: true,
          video: false,
        });
        setStream(mediaStream);
      } catch (err) {
        alert(err.message);
      }
    } else {
      alert("The MediaRecorder API is not supported in your browser.");
    }
  };

  const startRecording = () => {
    if (stream) {
      const localAudioChunks = [];
      const media = new MediaRecorder(stream, { type: "audio/wav" });

      media.ondataavailable = (event) => {
        if (event.data.size > 0) {
          localAudioChunks.push(event.data);
        }
      };

      media.onstop = async () => {
        const audioBlob = new Blob(localAudioChunks, { type: "audio/wav" });
        await stopListening(audioBlob);
        setAudioChunks([]);
      };

      mediaRecorder.current = media;
      mediaRecorder.current.start();
      setIsRecording(true);
    }
  };

  const stopRecording = () => {
    if (mediaRecorder.current) {
      mediaRecorder.current.stop();
      setIsRecording(false);
    }
  };

  return (
    <div className="flex justify-center w-screen">
      {isRecording ? (
        <div
          className="rounded-2xl bg-white text-darkgray text-center p-2"
          onClick={stopRecording}
        >
          Stop Recording
        </div>
      ) : (
        <div
          className="rounded-2xl bg-white text-darkgray text-center p-2"
          onClick={startRecording}
        >
          Start Recording
        </div>
      )}
    </div>
  );
};

export default AudioRecorder;
