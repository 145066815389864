import React from "react";
import Title from "./Title";
import { PulseLoader } from "react-spinners";

export default function WidgetButton(props) {
  const handleClick = () => {
    if (props.status === "pending") {
      console.log("Cannot click when status is pending");
    } else {
      props.onClick();
    }
  };

  return (
    <div
      className={`bg-secondary flex justify-between items-center px-4 py-4 rounded my-4 ${
        props.status === "pending" ? "opacity-70 pointer-events-none" : ""
      }`}
      onClick={handleClick}
    >
      <Title label={props.label} color="darkgray" />
      {props.status === "pending" ? (
        <div className="flex items-center">
          {/* <span className="text-darkgray mr-2">Processing</span> */}
          <PulseLoader color="#060B1F" size="5px" />
        </div>
      ) : (
        <img
          src={require("../assets/icons/arrow_forward.png")}
          className="w-2 h-4"
          alt="arrow-forward"
        />
      )}
    </div>
  );
}
