import React, { useState } from "react";
import Text from "./Text";

export default function HighlightWordComponent({ data }) {
  const [expand, setExpand] = useState(false);
  return (
    <div className="bg-[#171C29] px-8 py-4 my-2 rounded-md border-[#70737D]">
      <div className="flex">
        <div className="flex items-center justify-between w-10/12">
          <Text color="white" label={data.old_word} />
          <img
            src={require("../assets/icons/straight_arrow_right.png")}
            alt="arrow_right"
            style={{ width: "24px", height: "12px" }}
          />
          <Text color="secondary" label={data.new_word} />
        </div>
        <div className="flex items-center justify-end w-2/12">
          <img
            src={require("../assets/icons/down.png")}
            alt="down"
            style={{ width: "20px", height: "11px" }}
            className={`${expand ? "rotate-180" : ""}`}
            onClick={() => {
              expand ? setExpand(false) : setExpand(true);
            }}
          />
        </div>
      </div>
      {expand && (
        <div className="py-4">
          <div className="px-2">
            <div className="flex space-between items-center">
              <img
                className="w-2 h-2 mr-5"
                src={require("../assets/icons/ellipse.png")}
                alt="tick"
              />
              <Text
                label={data.old_phrase}
                color="white"
                underlineWord={data.old_word}
              />
            </div>
            <div className="flex space-between items-center">
              <img
                className="w-3 h-2 mr-4"
                src={require("../assets/icons/tick.png")}
                alt="tick"
              />
              <Text
                label={data.new_phrase}
                color="white"
                boldWord={data.new_word}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
