import executeGraphQLQuery from "./graphql";

export const getBatches = () => {
  const query = `
    query {
      batches(where: {status: {_eq: "in_progress"}}) {
        id
        name
      }
    }
  `;
  return executeGraphQLQuery(query);
};
