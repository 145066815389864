import executeGraphQLQuery from "./graphql";

export const getLearnersByBatchId = (batchId) => {
  const query = `
    query {
      learners(where: {status: {_eq: "active"}, _and: {batch_id: {_eq: ${batchId}}}}) {
        id
        name
      }
    }
  `;
  return executeGraphQLQuery(query);
};
