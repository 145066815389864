import React, { useState, useEffect } from "react";

import ImageIconButton from "../../../components/ImageIconButton";
import Heading from "../../../components/Heading";
import GrammerCorrect from "../../../components/GrammerCorrect";

import { getGrammarData } from "../../../api/services/ai";

export default function Grammar(props) {
  const { userId, assessmentId, userType } = props;
  const [grammarData, setGrammarData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const comparisonData = await getGrammarData(
          userId,
          assessmentId,
          userType
        );
        setGrammarData(comparisonData.sentences);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="flex flex-col h-screen">
        <div className="flex-shrink-0 text-white px-6 py-8">
          <ImageIconButton
            src={require("../../../assets/icons/arrow_back.png")}
            onClick={() => props.setSelectedWidget(null)}
          />
          <Heading title="Accuracy Check" />
        </div>
        <div className="flex-1 overflow-y-scroll px-6 mb-4">
          {grammarData &&
            grammarData.map((grammerCorrectData) => (
              <GrammerCorrect data={grammerCorrectData} />
            ))}
        </div>
      </div>
    </div>
  );
}
