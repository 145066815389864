import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import ImageIconButton from "../../../components/ImageIconButton";
import Heading from "../../../components/Heading";
import SubHeading from "../../../components/SubHeading";
import PrimaryButton from "../../../components/PrimaryButton";
import SentenceCompare from "../../../components/SentenceCompare";
import { getSentenceComparisonData } from "../../../api/services/ai";
import Text from "../../../components/Text";

const customStyles = {
  content: {
    width: "80%",
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    borderRadius: "8px",
  },
};

export default function SentenceComparison(props) {
  const { userId, assessmentId, userType } = props;
  const [sentenceComparisonData, setSentenceComparisonData] = useState(null);
  // const [modalIsOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const comparisonData = await getSentenceComparisonData(
          userId,
          assessmentId,
          userType
        );

        setSentenceComparisonData(comparisonData.sentences);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // useEffect(() => {
  // let showModal = localStorage.getItem("showModal");
  // showModal = showModal === null ? true : JSON.parse(showModal);
  // setIsOpen(showModal);
  // }, []);

  // const handleChange = (event) => {
  //   localStorage.setItem("showModal", !event.target.checked);
  // };

  return (
    <div>
      <div className="flex flex-col h-screen">
        <div className="flex-shrink-0 text-white px-6 py-8">
          <ImageIconButton
            src={require("../../../assets/icons/arrow_back.png")}
            onClick={() => props.setSelectedWidget(null)}
          />
          <Heading title="Sentence Check" />
        </div>
        <div className="flex-1 overflow-y-scroll px-6 my-4">
          {sentenceComparisonData &&
            sentenceComparisonData.map((sentenceComparison) => (
              <SentenceCompare data={sentenceComparison} />
            ))}
        </div>

        <div className="flex-shrink-0 flex justify-center mb-4">
          <PrimaryButton
            label="Let’s Practice Again!"
            onClick={() => props.handlePrimaryButtonClick()}
          />
        </div>
      </div>
      {/* <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setIsOpen(false)}
        style={customStyles}
        ariaHideApp={false}
      >
        <div className="px-2">
          <div className="flex justify-between items-center my-4">
            <img
              src={require("../../assets/icons/speaker_dark.png")}
              alt="speaker_dark"
              className="w-12 h-6 mr-2"
            />
            <Text
              color="darkgray"
              label="Listen to the suggested sentences and practice them."
            />
          </div>
          <div className="flex justify-center">
            <PrimaryButton label="Ok!" onClick={() => setIsOpen(false)} />
          </div>
          <div className="flex justify-end text-darkgray pt-2">
            <input type="checkbox" onChange={handleChange} className="mr-2" />
            <p className="text-sm">Don't show it again</p>
          </div>
        </div>
      </Modal> */}
    </div>
  );
}
