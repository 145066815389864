import React from "react";

export default function PrimaryButton(props) {
  return (
    <button
      className=" bg-primary text-white font-medium font-sans py-4 mb-5 rounded w-11/12"
      onClick={props.onClick}
      style={props.style}
    >
      {props.label}
    </button>
  );
}
