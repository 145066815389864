import React, { useEffect, useState } from "react";

import Heading from "../components/Heading";

import { getBatches } from "../api/services/graphql/batches";
import { getLearnersByBatchId } from "../api/services/graphql/learners";
import {
  getDCAssessmentLandingPage,
  getDCJobProfile,
  getDCJobScenarios,
} from "../api/services/multispeakerTranscribe";
import Title from "../components/Title";
import Text from "../components/Text";
import JobProfile from "./widgets/ScenarioAnalysis/JobProfile";
import MediaPlayer from "../components/MediaPlayer";
import toast, { Toaster } from "react-hot-toast";

export default function LearnerScenarios() {
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [selectedLearner, setSelectedLearner] = useState(null);
  const [batches, setBatches] = useState([]);
  const [learners, setLearners] = useState([]);
  const [landingPageData, setLandingPageData] = useState(null);
  const [dCJobScenarioData, setDCJobScenarioData] = useState([]);
  const [dcJobProfileData, setDCJobProfileData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const batchesData = await getBatches();
        setBatches(batchesData.batches);
        console.log("Batches:", batchesData);
      } catch (error) {
        console.error("Error fetching batches:", error.message);
        toast("Error fetching batches", {
          icon: "ℹ️",
        });
      }
    };
    fetchData();
  }, []);

  const handleBatchChange = async (batchId) => {
    setSelectedBatch(batchId);
    try {
      const learnersData = await getLearnersByBatchId(batchId);
      setLearners(learnersData.learners);
      console.log("Learners:", learnersData);
    } catch (error) {
      console.error("Error fetching learners:", error.message);
      toast("Error fetching learners", {
        icon: "ℹ️",
      });
    }
  };

  const handleLearnerChange = async (learnerId) => {
    setSelectedLearner(learnerId);
    try {
      const landingPageJSONData = await getDCAssessmentLandingPage(
        learnerId,
        "learner"
      );
      console.log(landingPageJSONData);
      setLandingPageData(landingPageJSONData);

      const dcJobProfileData = await getDCJobProfile(learnerId);
      setDCJobProfileData(dcJobProfileData);
      console.log("DC Job Profile Data:", dcJobProfileData);

      const dCJobScenarioData = await getDCJobScenarios(learnerId);
      setDCJobScenarioData(dCJobScenarioData.scenarios);
      console.log("DC Job Scenarios:", dCJobScenarioData.scenarios);
    } catch (error) {
      console.error("Error fetching DC job scenarios:", error.message);
      setDCJobProfileData(null);
      setDCJobScenarioData(null);
      toast("No Data", {
        icon: "ℹ️",
      });
    }
  };

  return (
    <div className="bg-darkgray min-h-screen p-12">
      <Toaster />
      <Heading title="Learner Scenarios" />
      <div className="py-5 flex space-x-4 items-center">
        <Title label="Select Batch:" color="secondary" />
        <select
          id="batchDropdown"
          onChange={(e) => handleBatchChange(e.target.value)}
          className="border-2 border-gray-300 rounded-md p-2"
        >
          <option value="" disabled selected>
            Choose a Batch
          </option>
          {batches.map((batch) => (
            <option key={batch.id} value={batch.id}>
              {batch.name}
            </option>
          ))}
        </select>
      </div>

      {selectedBatch && (
        <div className="py-5 flex space-x-4 items-center">
          <Title label="Select Learner:" color="secondary" />
          <select
            id="learnerDropdown"
            onChange={(e) => handleLearnerChange(e.target.value)}
            className="border-2 border-gray-300 rounded-md p-2"
          >
            <option value="" disabled selected>
              Choose a Learner
            </option>
            {learners.map((learner) => (
              <option key={learner.id} value={learner.id}>
                {learner.name}
              </option>
            ))}
          </select>
        </div>
      )}

      {landingPageData && landingPageData.url && (
        <MediaPlayer url={landingPageData.url} />
      )}

      {dcJobProfileData && (
        <div className="py-5">
          <Title label={`Job Profile`} color="secondary" />
          {dcJobProfileData.job_profile && (
            <Text color="lightgray" label={dcJobProfileData.job_profile} />
          )}
        </div>
      )}

      {dCJobScenarioData && dCJobScenarioData.length > 0 && (
        <div className="py-5">
          <Title
            label={`AI Generated Scenarios (${dCJobScenarioData.length})`}
            color="secondary"
          />
          <div className="my-2 flex space-x-2">
            <div className="bg-[#171C29] flex justify-center items-center px-8 w-1/12">
              <Text color="secondary" label={"Sl No."} />
            </div>
            <div className="bg-[#171C29] flex justify-center items-center px-8 w-1/6">
              <Text color="secondary" label={"Title"} />
            </div>
            <div className="bg-[#171C29] flex justify-center items-center px-8 w-1/6">
              <Text color="secondary" label={"Audiences"} />
            </div>
            <div className="bg-[#171C29] flex justify-center items-center px-8 py-4 w-7/12">
              <Text color="secondary" label={"Scenario"} />
            </div>
          </div>
          <div className="text-white">
            {dCJobScenarioData.map((scenario, index) => (
              <div className="my-2 flex space-x-2" key={index}>
                <div className="bg-[#171C29] flex justify-center items-center px-8 w-1/12">
                  <Text color="lightgray" label={(index + 1).toString()} />
                </div>
                <div className="bg-[#171C29] flex justify-center items-center px-8 w-1/6">
                  <Text color="lightgray" label={scenario.title} />
                </div>
                <div className="bg-[#171C29] flex justify-center items-center px-8 w-1/6">
                  <Text color="lightgray" label={scenario.audiences} />
                </div>
                <div className="bg-[#171C29] flex justify-center items-center px-8 py-4 w-7/12">
                  <Text color="lightgray" label={scenario.scenario} />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
