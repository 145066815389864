import React from "react";
import Text from "./Text";
import Title from "./Title";
import useTextToSpeech from "../hooks/useTextToSpeech";

export default function BottomSheet({ expand, setExpand, ...props }) {
  const { speakText, speaking, cancel } = useTextToSpeech();

  const handleSpeaking = () => {
    if (speaking) cancel();
    else speakText(props.content);
  };

  return (
    <div className="bg-secondary rounded-tr-2xl	 rounded-tl-2xl px-8 py-4 absolute bottom-0 w-full">
      <div className="flex justify-between items-center">
        <Title label={props.title} color="darkgray" />
        <div className="flex justify-between">
          {/* <div
            className="mx-4 p-2 bg-darkgray rounded-3xl"
            onClick={handleSpeaking}
          >
            {speaking ? (
              <img
                src={require("../assets/icons/pause-light.png")}
                alt="arrow_right"
                key="arrow_right"
                style={{ width: "14px", height: "14px" }}
              />
            ) : (
              <img
                src={require("../assets/icons/speaker.png")}
                alt="speaker"
                key="speaker"
                className="bg-black"
                style={{ width: "14px", height: "14px" }}
              />
            )}
          </div> */}
          <button
            onClick={() => {
              expand ? setExpand(false) : setExpand(true);
            }}
          >
            {expand ? (
              <img
                src={require("../assets/icons/collapse.png")}
                alt="expand"
                className="w-5"
                key="collapse"
              />
            ) : (
              <img
                src={require("../assets/icons/expand.png")}
                alt="expand"
                className="w-5"
                key="expand"
              />
            )}
          </button>
        </div>
      </div>
      <div className={`overflow-scroll ${expand ? "h-[256px] pt-2" : "h-0"}`}>
        <Text label={props.content} color="darkgray" />
      </div>
    </div>
  );
}
