import React, { useState, useEffect, useRef } from "react";
import Vapi from "@vapi-ai/web";
import StartRecording from "../assets/icons/start-recording.png";
import ResetRecordingIcon from "../assets/icons/retry-recording.png";
import Waves from "../components/Waves";
import UnmuteIcon from "../assets/icons/mute.png"; // Add your mute icon
import MuteIcon from "../assets/icons/unmute.png"; // Add your unmute icon
export const vapi = new Vapi("b236f57b-842b-4c8d-9e20-8a0ebdd57145");

let mediaRecorder;
const apiUrl = "https://staging.ai.vani.coach/api/AiRoleplayVapi/";

const AiRoleplayVapi = () => {
    const [seconds, setSeconds] = useState(0);
    const [isRecording, setIsRecording] = useState(false);
    const [paused, setPaused] = useState(false);
    const [responses, setResponses] = useState([]); // Initialize as an array

    const [prevClientID, checkPrevClientId] = useState('');
    const [showAskPrev, setShowAksPrev] = useState(false);
    const [noisemultiplier, setNoisemultiplier] = useState(40); // New state for noise multiplier
    const [pauseTime, setPauseTime] = useState(1250);
    const [isMuted, setIsMuted] = useState(false); // New state for mute status
    const [playing, setPlaying] = useState(false);



    const [callStatus, setCallStatus] = useState("inactive");
//   const [response, setResponse] = useState([]);
//   const [message,setMessage] = useState('');

  const start = async () => {
    setCallStatus("loading");
    try {
      // Start the call
      await vapi.start("be0e8c96-8a13-4ea5-9ddc-f0134e65956f"); // Use your assistant ID
    } catch (error) {
      console.error("Failed to start call:", error);
      setCallStatus("inactive");
    }
  };

  const stop = () => {
    setCallStatus("loading");
    vapi.stop();
  };

  useEffect(() => {
    const handleCallStart = () => setCallStatus("active");
    const handleCallEnd = () => setCallStatus("inactive");
    // const handleMessage = (message) => {
    //   console.log("Received message:", message);

    //    const lastElement=message;
    //   if (lastElement.messagesOpenAIFormatted && lastElement.messagesOpenAIFormatted.length > 0) {
    //   // Find the last message with role 'assistant'
    //   const lastMessage = lastElement.messagesOpenAIFormatted
    //     .filter(msg => msg.role === 'assistant')
    //     .pop();

    //   // Return or display the message
    //   const msg = lastMessage ? lastMessage.content : 'No assistant message found'
    //   console.log(msg);
    //   setMessage(msg);
    // } else {
    //   console.log('No messages available');
    // }

    //   setResponse(prevMessages => [...prevMessages, message]);
    // };

    // vapi.on("message", handleMessage);
    vapi.on("call-start", handleCallStart);
    vapi.on("call-end", handleCallEnd);

    return () => {
        // vapi.off("message", handleMessage);
      vapi.off("call-start", handleCallStart);
      vapi.off("call-end", handleCallEnd);
    };
  }, []);

    const createID = async () => {

    };

    const handleStart = () => {

    };

    const handleMuteToggle = () => {

    }

    const handleClickStop = () => {
        stop();
        setIsRecording(false);
        setResponses([]);
    };

    const handleClickStart = () => {

    //   setShowAksPrev(true);
      setIsRecording(true);
      start();

    setSeconds(0);
    };

    const handleClickReset = () => {

    };

    const handleExit = () => {

      };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const remainingSeconds = time % 60;
        return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
    };


    return (
        <div className="flex justify-center h-screen pt-10 w-full space-y-8">
            <div className="h-[80vh] w-100 h-100 rounded-lg p-5 relative">
                {playing && <div>Playing</div>}
                {isRecording &&
                    <div className="absolute top-2 right-2" onClick={() => handleMuteToggle()}>
                        <img
                            src={isMuted ? UnmuteIcon : MuteIcon}
                            alt="Mute/Unmute"
                            className="w-8 h-8 cursor-pointer"
                        />
                    </div>
                }
                <div className="h-[40vh] flex items-center justify-center relative flex-col">
                    {callStatus === "loading" ? <i>Loading...</i> : null}
                    {paused && isRecording && <span className="text-gray-500">Generating Response</span>}
                    {/* {console.log(messages)} */}
                    {/* {callStatus=='active' && message} */}
                    {responses.length > 0 && isRecording && (
                        <div className="mt-4 max-h-48 overflow-y-auto border border-gray-300 p-2 z-10">
                            {responses.map((response, index) => (
                                <div key={index}>{JSON.stringify(response.result)}</div>
                            ))}
                        </div>
                    )}
                </div>

                <div className="flex justify-center w-full">
                    {isRecording && (
                        <div className="rounded-2xl text-darkgray text-center p-2 flex flex-col items-center space-y-2 mt-20">

                            <div className="rounded-[30px] bg-green1 text-darkgray text-center h-12 flex flex-col justify-center items-center space-y-2 cursor-pointer mt-4" onClick={handleClickStop}>
                                <p className="flex items-center text-sm font-bold text-white px-12 font-sans">End Conversation</p>
                            </div>
                            <p className="text-orange-500 font-sans">{formatTime(seconds)}</p>
                        </div>
                    )}
                    {!isRecording && !showAskPrev && seconds === 0 && (
                        <div className="rounded-2xl text-darkBlue2 text-center p-2 flex flex-col items-center space-y-2">
                            <img src={StartRecording} alt="StartRecording" className="w-24 cursor-pointer" onClick={handleClickStart} />
                            <p className="font-sans text-lg font-bold text-darkBlue2 mt-2"> Start Taking </p>
                        </div>
                    )}

                    {!isRecording && showAskPrev && prevClientID && (
                        <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
                            <div className="bg-white p-5 rounded-lg text-center max-w-xs">
                                <div className="font-bold">
                                    Do you want to continue with your previous Conversation?
                                </div>
                                <div className="mt-2 flex items-center justify-evenly">
                                    <div className="cursor-pointer mt-2 w-fit px-4 py-2 rounded bg-customRed text-white" onClick={() => {
                                        setIsRecording(true);
                                        setShowAksPrev(false);
                                        handleStart();
                                    }}>
                                        YES
                                    </div>
                                    <div className="cursor-pointer mt-2 w-fit px-4 py-2 rounded bg-customRed text-white" onClick={() => {
                                        setIsRecording(true);
                                        checkPrevClientId('');
                                        localStorage.removeItem('ClientId');
                                        createID();
                                        setShowAksPrev(false);
                                        handleStart();
                                    }}>
                                        NO
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!isRecording && !showAskPrev && seconds > 0 && (
                        <div className="space-x-4 mt-12">
                            <div className="flex items-center">
                                <div className="flex flex-col items-center">
                                    <img src={ResetRecordingIcon} alt="ResetRecording" className="w-15 h-14 cursor-pointer" onClick={handleClickReset} />
                                </div>
                                <p className="font-sans text-lg font-bold text-darkBlue2 ml-4"> Start Again </p>
                            </div>

                            <div className="rounded-[30px] bg-orange-500 text-darkgray text-center h-12 flex flex-col justify-center items-center space-y-2 mt-12">
                                <p className="flex items-center text-sm font-bold text-white px-12 font-sans" onClick={handleExit}>Exit</p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {isRecording && <div className="absolute h-80 w-96 rounded-2xl overflow-hidden">
                <Waves className="w-full" />
            </div>}
        </div>
    );
};

export default AiRoleplayVapi;