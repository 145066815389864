import React, { useState, useEffect } from "react";

import RolePlayChat from "./widgets/RolePlay/RolePlayChat";
import Heading from "../components/Heading";
import { getRoleplaySituations } from "../api/services/roleplay";
import WidgetButton from "../components/WidgetButton";

export default function RolePlay() {
  const [roleplaySituationUid, setRoleplaySituationUid] = useState(null);
  const [roleplaySituations, setRoleplaySituations] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const roleplaySituationsData = await getRoleplaySituations();
        setRoleplaySituations(roleplaySituationsData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="bg-darkgray h-screen w-screen">
      {roleplaySituationUid != null && (
        <RolePlayChat roleplay_situation_uid={roleplaySituationUid} />
      )}
      {roleplaySituationUid === null && (
        <div>
          <div className="text-white px-6 py-8">
            <Heading title="Roleplay Situations" />
            {roleplaySituations &&
              roleplaySituations.length > 0 &&
              roleplaySituations.map((roleplaySituation) => (
                <WidgetButton
                  key={roleplaySituation._id}
                  label={roleplaySituation.title}
                  onClick={() =>
                    setRoleplaySituationUid(
                      roleplaySituation.roleplay_situation_uid
                    )
                  }
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
