import React from "react";
import { useLocation } from "react-router-dom";

export default function Error() {
  const location = useLocation();
  const aiAnalysisStatus = location.state?.aiAnalysisStatus;
  let errorMessage = "There seems to be a technical issue";
  let heading = "Oops!";
  let subtext = "";

  if (aiAnalysisStatus === "verification_failed") {
    heading = "Sorry, I couldn't analyze your response";
    subtext =
      "Ensure that there is no background noise and you record for at least a minute";
    errorMessage = "";
  } else if (aiAnalysisStatus === "short_recording") {
    errorMessage = "Record for at least 30 seconds!";
  }

  return (
    <div
      className="flex flex-col items-center justify-center w-100% h-screen px-8 py-10 space-y-12 bg-no-repeat bg-contain bg-bottom"
      style={{
        backgroundImage: `url(${require("../assets/images/grey-bg.png")})`,
      }}
    >
      <div className="flex flex-col items-center justify-center space-y-4">
        <img
          className="h-12 w-12"
          src={require("../assets/icons/cross-outline-orange.png")}
          alt="cross"
        />
        <div className="flex flex-col items-center justify-center">
          <p className="text-darkBlue3 font-sans text-lg font-bold">
            {heading}
          </p>
          {errorMessage && (
            <p className="text-darkBlue3 text-center font-sans text-lg">
              {errorMessage}
            </p>
          )}
          {subtext && (
            <p className="text-darkBlue3 text-center font-sans text-lg">
              {subtext}
            </p>
          )}
        </div>
        {aiAnalysisStatus === "verification_failed" && (
          <div className="flex flex-col space-y-2">
            <button
              className="bg-primary text-white font-medium py-2 px-8 rounded-[30px] w-full tracking-wide"
              onClick={() =>
                (window.location.href =
                  "https://staging.experience.vani.coach/record")
              }
            >
              Record again
            </button>
            <button
              className="text-primary text-sm px-4 rounded-[30px] tracking-wide underline"
              onClick={() => (window.location.href = "https://vani.coach/")}
            >
              I will leave
            </button>
          </div>
        )}
      </div>
    </div>
  );
}
