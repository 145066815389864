import React, { useEffect, useState } from "react";

const JobProfileQuestion = ({ onNext }) => {
  const [isRelated, setIsRelated] = useState("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleRadioChange = (event) => {
    setIsRelated(event.target.value);
  };

  const handleSubmit = () => {
    onNext(isRelated === "Yes");
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "trackMixpanelEvent",
        meta: { action: "AUDIENCE_SELECTED_CONTINUE" },
      })
    );
  };

  useEffect(() => {
    setIsButtonDisabled(isRelated === "");
  }, [isRelated]);

  return (
    <div className="flex flex-col items-center justify-start w-screen h-screen px-8 pt-10 space-y-8">
      <div className="flex items-center justify-between w-full">
        <div className="flex items-center justify-center space-x-4">
          <img
            className="h-6 w-6 mt-1 cursor-pointer"
            alt="cross"
            src={require("../../assets/icons/cross-outlined-grey.png")}
            onClick={() =>
              window.ReactNativeWebView.postMessage(
                JSON.stringify({ event: "navigateBack" })
              )
            }
          />
          <h1 className="text-2xl font-semibold text-darkgray font-sans">
            Customise your Playbook
          </h1>
        </div>
      </div>
      <div className="w-full">
        <h2 className="text-xl font-semibold text-darkgray font-sans">
          Is it related to your job profile?
        </h2>
        <div className="px-2 my-4 items-start space-y-4">
          {["Yes", "No"].map((option) => (
            <div className="flex items-center space-x-4" key={option}>
              <input
                className="flex-[0.1] h-5 w-5 accent-green1"
                type="radio"
                value={option}
                checked={isRelated === option}
                onChange={handleRadioChange}
              />
              <p className="flex-[0.9] text-darkgray font-sans">{option}</p>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full p-8 absolute bottom-0 rounded-t-2xl bg-white">
        <button
          className={`flex justify-center text-white font-semibold py-4 px-4 rounded-full w-full tracking-wider ${
            isButtonDisabled ? "bg-[#82858F]" : "bg-primary"
          }`}
          onClick={handleSubmit}
          disabled={isButtonDisabled}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default JobProfileQuestion;
