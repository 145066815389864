import React, { useState, useEffect } from "react";

import Title from "../../../components/Title";
import Text from "../../../components/Text";

import { startRoleplay, nextRoleplay } from "../../../api/services/roleplay";

import packageDetails from "../../../../package.json";
import AudioRecorder from "../../../components/AudioRecorder";

export default function RolePlayChat({ roleplay_situation_uid }) {
  const [, setRoleplayId] = useState(null);
  const [coachText, setCoachText] = useState("");
  const [fileUrl, setFileUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const startRoleplayRequest = async () => {
      setIsLoading(true);
      try {
        const payload = {
          userid: localStorage.getItem("userid"),
          usertype: "learner",
          batchid: "1",
          roleplay_situation_uid: roleplay_situation_uid,
        };
        const response = await startRoleplay(payload);
        setCoachText(response.text);
        setRoleplayId(response.id);
        localStorage.setItem("roleplay_id", response.id);

        // if (response.file_name) {
        //   setFileUrl(
        //     `${packageDetails.proxy}/api/roleplay/stream-audio?file_name=${response.file_name}`
        //   );
        // }
      } catch (error) {
        console.error("Error starting roleplay:", error.message);
      } finally {
        setIsLoading(false);
      }
    };
    startRoleplayRequest();
  }, []);

  const stopListening = async (audioBlob) => {
    const storedRoleplayId = localStorage.getItem("roleplay_id");
    await nextRoleplayRequest(storedRoleplayId, audioBlob);
  };

  const nextRoleplayRequest = async (id, audioBlob) => {
    setIsLoading(true);
    try {
      const response = await nextRoleplay(id, audioBlob);
      setCoachText(response.text);

      // if (response.file_name) {
      //   setFileUrl(
      //     `${packageDetails.proxy}/api/roleplay/stream-audio?file_name=${response.file_name}`
      //   );
      // }
    } catch (error) {
      console.error("Error getting next roleplay:", error.message);
    } finally {
      setIsLoading(false);
    }
  };

  // useEffect(() => {
  // if (fileUrl) {
  //   const audio = new Audio(fileUrl);
  //   audio.play();
  //   audio.removeAttribute("controls");
  // }
  // }, [fileUrl]);

  return (
    <div className="bg-darkgray h-screen">
      <div className="bg-primary h-[50vh] p-4">
        <div className="bg-gray-50 rounded-xl rounded-bl-none py-2 px-4">
          <Title label="Coach" />
          {isLoading ? <Text label="Loading..." /> : <Text label={coachText} />}
        </div>
      </div>
      <div className="h-[50vh] rounded p-4">
        <div className="bg-gray-50 rounded-xl rounded-br-none py-2 px-4">
          <Title label="Learner" align="right" />
        </div>
      </div>
      <div className="absolute bottom-4">
        <AudioRecorder stopListening={stopListening} />
      </div>
      {/* {fileUrl && (
        <audio autoPlay controls>
          <source src={fileUrl} type="audio/mp3" />
          Your browser does not support the audio element.
        </audio>
      )} */}
    </div>
  );
}
