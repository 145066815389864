import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import SuggestedSpeech from "./widgets/Feedback/SuggestedSpeech";
import SentenceComparison from "./widgets/Feedback/SentenceComparison";
import Vocabulary from "./widgets/Feedback/Vocabulary";
import Grammar from "./widgets/Feedback/Grammar";

import Heading from "../components/Heading";
import SubHeading from "../components/SubHeading";
import PrimaryButton from "../components/PrimaryButton";
import ImageIconButton from "../components/ImageIconButton";
import WidgetButton from "../components/WidgetButton";
import MediaPlayer from "../components/MediaPlayer";

import { getLandingPageData } from "../api/services/ai";

export default function Feedback() {
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [landingPageData, setLandingPageData] = useState(null);
  const [toastDisplayed, setToastDisplayed] = useState(false);

  const handleWidgetButtonClick = (widgetType) => {
    setSelectedWidget(widgetType);
  };

  const params = new URLSearchParams(window.location.search);
  const userId = params.get("userId");
  const assessmentId = params.get("assessmentId");
  const userType = params.get("userType") || "learner";

  const handlePrimaryButtonClick = () => {
    const situation = landingPageData?.situation;
    if (situation.id) {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: "navigateToPreviewSituation",
          meta: { situationId: situation.id },
        })
      );
    } else {
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: "navigateToSelectSituation",
        })
      );
    }
  };

  const handleCloseIconButtonClick = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "navigateBack",
      })
    );
  };

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        const landingPageJSONData = await getLandingPageData(
          userId,
          assessmentId,
          userType
        );
        setLandingPageData(landingPageJSONData);

        const hasPendingStatus = landingPageJSONData?.ai_analysis_list?.some(
          (analysisList) => analysisList.status === "pending"
        );

        console.log("toastDisplayed", toastDisplayed);
        if (hasPendingStatus && !toastDisplayed) {
          toast(
            "Some AI Assessments are in progress. It can take 2-3 minutes to complete. You can wait or come back in a while",
            {
              icon: "ℹ️",
            }
          );
          setToastDisplayed(true);
        }
        if (!hasPendingStatus) {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    intervalId = setInterval(fetchData, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [toastDisplayed]);

  return (
    <div className="bg-darkgray h-screen w-screen">
      <Toaster />
      {selectedWidget === "suggested_speech_json" && (
        <SuggestedSpeech
          setSelectedWidget={setSelectedWidget}
          userId={userId}
          assessmentId={assessmentId}
          userType={userType}
        />
      )}
      {selectedWidget === "sentence_comparison_json" && (
        <SentenceComparison
          setSelectedWidget={setSelectedWidget}
          userId={userId}
          assessmentId={assessmentId}
          userType={userType}
          handlePrimaryButtonClick={handlePrimaryButtonClick}
        />
      )}
      {selectedWidget === "vocabulary_json" && (
        <Vocabulary
          setSelectedWidget={setSelectedWidget}
          userId={userId}
          assessmentId={assessmentId}
          userType={userType}
        />
      )}
      {selectedWidget === "grammer_json" && (
        <Grammar
          setSelectedWidget={setSelectedWidget}
          userId={userId}
          assessmentId={assessmentId}
          userType={userType}
        />
      )}
      {selectedWidget === null && (
        <div className="flex flex-col h-screen">
          <div className="flex-shrink-0 text-white px-6 pt-8">
            <ImageIconButton
              src={require("../assets/icons/cross.png")}
              onClick={handleCloseIconButtonClick}
            />
            <Heading title="AI Feedback/Report" />
            {landingPageData &&
              landingPageData.situation &&
              landingPageData.situation.title && (
                <SubHeading content={landingPageData.situation.title} />
              )}
          </div>
          <div className="flex-1 overflow-y-scroll px-6">
            {landingPageData && landingPageData.audio_url && (
              <MediaPlayer url={landingPageData.audio_url} />
            )}
            {landingPageData &&
              landingPageData.ai_analysis_list &&
              landingPageData.ai_analysis_list.length > 0 &&
              landingPageData.ai_analysis_list.map((analysisList) => (
                <WidgetButton
                  key={analysisList.prompt_uid}
                  label={analysisList.title}
                  onClick={() =>
                    handleWidgetButtonClick(analysisList.prompt_uid)
                  }
                  status={analysisList.status}
                />
              ))}
          </div>
          <div className="flex-shrink-0 flex justify-center">
            <PrimaryButton
              label="Let’s Practice Again!"
              onClick={handlePrimaryButtonClick}
            />
          </div>
        </div>
      )}
    </div>
  );
}
