import axiosInstance from "../../axios";

const GRAPHQL_ENDPOINT = "https://staging.graphql.vani.coach/v1/graphql";

const headers = {
  "Content-Type": "application/json",
  "x-hasura-admin-secret": "stage_key__v1_@241",
};

const executeGraphQLQuery = async (query) => {
  try {
    const response = await axiosInstance.post(
      GRAPHQL_ENDPOINT,
      { query },
      { headers }
    );
    return response.data.data;
  } catch (error) {
    throw new Error("Error executing GraphQL query: " + error.message);
  }
};

export default executeGraphQLQuery;
