import React, { useEffect, useState } from "react";

const splashScreens = [
  {
    image: require("../../assets/images/game-on.png"),
    heading: "Game On!",
    subheading: "Your Playbook’s is Upgraded!<br/>Get ready to practice.",
    buttons: [{ type: "primary", label: "Let’s Practice!" }],
  },
  {
    image: require("../../assets/images/heavy-traffic.png"),
    heading: "Still Processing!",
    subheading:
      "It is taking longer than usual.<br/>Keep an eye on notifications.",
    buttons: [{ type: "primary", label: "Okay!" }],
  },
  {
    image: require("../../assets/images/try-again.png"),
    heading: "Try Again!",
    subheading: "Please provide more details to generate your scenarios.",
    buttons: [
      { type: "outline", label: "Cancel" },
      { type: "primary", label: "Okay!" },
    ],
  },
];

const SplashScreen = ({ response, loading, onNext }) => {
  const [screenIndex, setScreenIndex] = useState(0);

  useEffect(() => {
    if (response) {
      setScreenIndex(0);
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: "trackMixpanelEvent",
          meta: { action: "CONGRATS_SITUATIONS_UNLOCKED" },
        })
      );
    } else if (loading) {
      setScreenIndex(1);
      window.ReactNativeWebView.postMessage(
        JSON.stringify({
          event: "trackMixpanelEvent",
          meta: { action: "STILL_PROCESSING" },
        })
      );
    } else {
      setScreenIndex(2);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { image, heading, subheading, buttons } = splashScreens[screenIndex];

  return (
    <div className="flex flex-col w-screen min-h-screen overflow-hidden">
      <div className="flex-1 flex flex-col items-center justify-center">
        <img src={image} className="w-72" alt={heading} />
        <div className="text-center mt-4">
          <p className="font-sans text-xl font-bold text-darkgray tracking-wider">
            {heading}
          </p>
          <p
            className="font-sans text-base text-darkBlue3 mt-2 px-20"
            dangerouslySetInnerHTML={{ __html: subheading }}
          />
        </div>
      </div>
      <div className="flex w-full p-8 rounded-t-2xl bg-white space-x-2">
        {buttons.map((button) => {
          if (button.type === "primary") {
            return (
              <button
                key={button.label}
                className="flex justify-center bg-primary text-white font-semibold py-4 px-4 rounded-full w-full tracking-wider"
                onClick={() => onNext(screenIndex)}
              >
                {button.label}
              </button>
            );
          } else {
            return (
              <button
                key={button.label}
                className="flex justify-center border border-darkgray text-darkgray font-semibold py-4 px-4 rounded-full w-full tracking-wider"
                onClick={() =>
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({ event: "navigateBack" })
                  )
                }
              >
                {button.label}
              </button>
            );
          }
        })}
      </div>
      <div className="flex w-full px-8 py-10 absolute bottom-0 rounded-t-2xl bg-white space-x-2">
        {buttons.map((button) => {
          if (button.type === "primary") {
            return (
              <button
                key={button.label}
                className="flex justify-center bg-primary text-white font-semibold py-4 px-4 rounded-full w-full tracking-wider"
                onClick={() => onNext(screenIndex)}
              >
                {button.label}
              </button>
            );
          } else {
            return (
              <button
                key={button.label}
                className="flex justify-center border border-darkgray text-darkgray font-semibold py-4 px-4 rounded-full w-full tracking-wider"
                onClick={() =>
                  window.ReactNativeWebView.postMessage(
                    JSON.stringify({ event: "navigateBack" })
                  )
                }
              >
                {button.label}
              </button>
            );
          }
        })}
      </div>
    </div>
  );
};

export default SplashScreen;
