import React from "react";

export default function Title(props) {
  const colorVariants = {
    secondary: "text-secondary",
    primary: "text-primary",
    darkgray: "text-darkgray",
  };
  const alignVariants = {
    right: "text-right",
  };
  return (
    <div
      className={`${colorVariants[props.color]} ${
        alignVariants[props.align]
      } font-sans font-bold text-lg`}
    >
      {props.label}
    </div>
  );
}
