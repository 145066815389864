import { useSpeechSynthesis } from "react-speech-kit";

const useTextToSpeech = () => {
  const { speak, speaking, cancel, voices } = useSpeechSynthesis();
  const speakText = (text) => {
    if (speaking) cancel();
    speak({ text, voice: voices[150] });
  };

  return {
    speakText,
    speaking,
    cancel,
  };
};

export default useTextToSpeech;
