import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";

import JobProfile from "./widgets/ScenarioAnalysis/JobProfile";
import Audiences from "./widgets/ScenarioAnalysis/Audiences";
import Situations from "./widgets/ScenarioAnalysis/Situations";

import Heading from "../components/Heading";
import ImageIconButton from "../components/ImageIconButton";
import WidgetButton from "../components/WidgetButton";
import MediaPlayer from "../components/MediaPlayer";

import { getDCAssessmentLandingPage } from "../api/services/multispeakerTranscribe";
import Title from "../components/Title";
import Text from "../components/Text";

export default function ScenarioAnalysis() {
  const [selectedWidget, setSelectedWidget] = useState(null);
  const [landingPageData, setLandingPageData] = useState(null);
  const [toastDisplayed, setToastDisplayed] = useState(false);

  const handleWidgetButtonClick = (widgetType) => {
    setSelectedWidget(widgetType);
  };

  const params = new URLSearchParams(window.location.search);
  const userId = params.get("userId");

  const handleCloseIconButtonClick = () => {
    window.ReactNativeWebView.postMessage(
      JSON.stringify({
        event: "navigateBack",
      })
    );
  };

  useEffect(() => {
    let intervalId;

    const fetchData = async () => {
      try {
        const landingPageJSONData = await getDCAssessmentLandingPage(
          userId,
          "learner"
        );
        console.log(landingPageJSONData);
        setLandingPageData(landingPageJSONData);

        const hasPendingStatus = landingPageJSONData?.ai_analysis_list?.some(
          (analysisList) => analysisList.status === "pending"
        );

        console.log("toastDisplayed", toastDisplayed);
        if (hasPendingStatus && !toastDisplayed) {
          toast(
            "Some AI Assessments are in progress. It can take 2-3 minutes to complete. You can wait or come back in a while",
            {
              icon: "ℹ️",
            }
          );
          setToastDisplayed(true);
        }
        if (!hasPendingStatus) {
          clearInterval(intervalId);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    intervalId = setInterval(fetchData, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [toastDisplayed]);

  return (
    <div className="bg-darkgray min-h-screen w-screen">
      <Toaster />
      {selectedWidget === "job_profile_json" && (
        <JobProfile setSelectedWidget={setSelectedWidget} userId={userId} />
      )}
      {selectedWidget === "audiences_json" && (
        <Audiences setSelectedWidget={setSelectedWidget} userId={userId} />
      )}
      {selectedWidget === "situations_json" && (
        <Situations setSelectedWidget={setSelectedWidget} userId={userId} />
      )}
      {selectedWidget === null && (
        <div>
          <div className="text-white px-6 py-8">
            <ImageIconButton
              src={require("../assets/icons/cross.png")}
              onClick={handleCloseIconButtonClick}
            />
            <Heading title="AI Scenario Analysis" />
            {landingPageData && landingPageData.url && (
              <MediaPlayer url={landingPageData.url} />
            )}
            {landingPageData && landingPageData.transcription && (
              <div>
                <Title color="secondary" label="Transcription" />
                <Text label={landingPageData.transcription} />
              </div>
            )}
            {landingPageData &&
              landingPageData.ai_analysis_list &&
              landingPageData.ai_analysis_list.length > 0 &&
              landingPageData.ai_analysis_list.map((analysisList) => (
                <WidgetButton
                  key={analysisList.prompt_uid}
                  label={analysisList.title}
                  onClick={() =>
                    handleWidgetButtonClick(analysisList.prompt_uid)
                  }
                  status={analysisList.status}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
