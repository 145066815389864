import errorimg from "../assets/icons/error.svg";
import { useNavigate } from "react-router-dom";

const EvaluateError = () => {
  const navigate = useNavigate();

  const handleClickEnrollNow = async () => {
    const email = localStorage.getItem("email");
    console.log(email);

    const leadData = {
      email: email,
      flag: "u",
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_THOR_UR}add_leads_data`,
        {
          method: "POST",
          headers: {
            accept: "application/json, text/plain, */*",
            "content-type": "application/json",
          },
          body: JSON.stringify(leadData),
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      console.log("API Response:", data);
    } catch (error) {
      console.error("Error:", error);
      // setError(error);
    }

    navigate("/congratulations");
  };

  return (
    <div className="flex justify-center h-dvh w-full font-sans">
      <div className="h-full w-full rounded-lg p-8 flex flex-col justify-between">
        <div className="h-[75vh] w-full flex justify-center flex-col items-center">
          <div>
            <img
              src={errorimg}
              className="h-[15rem] w-auto rounded-lg mb-4"
              alt="Blub"
            />
          </div>
          <div className="text-darkgray font-[800] text-[1.5rem]">
            Try Again!
          </div>
          <div className="text-gray-600 text-base text-center mt-1">
            We need a bit more to work with! Try recording for{" "}
            <span className="font-bold">more than 1 min </span> for the best Al
            feedback.
          </div>
        </div>
        <div className="z-10 flex items-end justify-center w-full">
          <div className="flex justify-around w-full">
            <button className="py-4 mr-2 rounded-full bg-white text-darkgray hover:bg-gray-300 border border-darkgray font-semibold flex-1 h-14">
              Retry
            </button>
            <button
              className="py-4 ml-2 rounded-full bg-orange1 text-white hover:bg-orange-700 font-semibold flex-1 h-14"
              onClick={() => handleClickEnrollNow()}
            >
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EvaluateError;
