import axiosInstance from "../axios";

const MULTISPEAKER_TRANSCRIBE_ENDPOINT = "/api/multispeaker-transcribe";

export const getDCAssessmentLandingPage = async (userId) => {
  try {
    const response = await axiosInstance.get(
      `${MULTISPEAKER_TRANSCRIBE_ENDPOINT}/get-dc-assessment-landing-page`,
      {
        params: {
          userid: userId,
          usertype: "learner",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      "Error fetching DC Assessment Landing Page data: " + error.message
    );
  }
};

export const getDCJobProfile = async (userId) => {
  try {
    const response = await axiosInstance.get(
      `${MULTISPEAKER_TRANSCRIBE_ENDPOINT}/get-dc-job-profile`,
      {
        params: {
          userid: userId,
          usertype: "learner",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching DC Job Profile data: " + error.message);
  }
};

export const getDCJobScenarioAudience = async (userId) => {
  try {
    const response = await axiosInstance.get(
      `${MULTISPEAKER_TRANSCRIBE_ENDPOINT}/get-dc-job-scenario-audience`,
      {
        params: {
          userid: userId,
          usertype: "learner",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error(
      "Error fetching DC Job Scenario Audience data: " + error.message
    );
  }
};

export const getDCJobScenarios = async (userId) => {
  try {
    const response = await axiosInstance.get(
      `${MULTISPEAKER_TRANSCRIBE_ENDPOINT}/get-dc-job-scenarios`,
      {
        params: {
          userid: userId,
          usertype: "learner",
        },
      }
    );
    return response.data;
  } catch (error) {
    throw new Error("Error fetching DC Job Scenarios data: " + error.message);
  }
};
