import React, { useState, useEffect, useRef } from "react";
import StartRecording from "../assets/icons/start-recording.png";
import ResetRecordingIcon from "../assets/icons/retry-recording.png";
import Waves from "../components/Waves";
import UnmuteIcon from "../assets/icons/mute.png"; // Add your mute icon
import MuteIcon from "../assets/icons/unmute.png"; // Add your unmute icon

let mediaRecorder;
const apiUrl = "https://staging.ai.vani.coach/api/airoleplay/";

const AiRoleplay = () => {
  const [seconds, setSeconds] = useState(0);
  const [isRecording, setIsRecording] = useState(false);
  const [paused, setPaused] = useState(false);
  const [responses, setResponses] = useState([]); // Initialize as an array
  const [audioChunks, setAudioChunks] = useState([]);
  const audioChunksRef = useRef([]); // Ref to store audio chunks
  const [prevClientID, checkPrevClientId] = useState('');
  const [showAskPrev, setShowAksPrev] = useState(false);
  const [noisemultiplier, setNoisemultiplier] = useState(40); // New state for noise multiplier
  const [pauseTime, setPauseTime] = useState(1250);
  const [isMuted, setIsMuted] = useState(false); // New state for mute status
  const [playing, setPlaying] = useState(false);

  const noisemultiplierRef = useRef(noisemultiplier); // Ref to store the noise multiplier

  const [isSpeaking, setIsSpeaking] = useState(false);
  const isSpeakingRef = useRef(isSpeaking); // Ref to store the isSpeaking state
  const [isAudioInitialized, setIsAudioInitialized] = useState(false);
  const [isCalibrating, setIsCalibrating] = useState(false);
  const [calibrationCountdown, setCalibrationCountdown] = useState(3);
  const audioContextRef = useRef(null);
  const analyserRef = useRef(null);
  const microphoneRef = useRef(null);
  const audioWorkletNodeRef = useRef(null);
  const silenceTimeoutRef = useRef(null);
  const calibrationDataRef = useRef([]);
  const isCalibratingRef = useRef(false); // Ref to store the isCalibrating state
  const ambientNoiseLevelRef = useRef(0); // Ref to store the ambientNoiseLevel
  const audioRef = useRef(null); // Ref to store the audio element

  const initAudio = async () => {
    try {
      audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
      await audioContextRef.current.audioWorklet.addModule('volumeProcessor.js');
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      microphoneRef.current = audioContextRef.current.createMediaStreamSource(stream);
      analyserRef.current = audioContextRef.current.createAnalyser();
      audioWorkletNodeRef.current = new AudioWorkletNode(audioContextRef.current, 'volumeProcessor');

      microphoneRef.current.connect(analyserRef.current);
      analyserRef.current.connect(audioWorkletNodeRef.current);
      audioWorkletNodeRef.current.connect(audioContextRef.current.destination);

      audioWorkletNodeRef.current.port.onmessage = (event) => {
        const { average } = event.data;

        if (isCalibratingRef.current) {
          console.log('Calibrating: ', average);
          calibrationDataRef.current.push(average);
        } else {
          if (average > ambientNoiseLevelRef.current * noisemultiplierRef.current) { // Use the noisemultiplier ref
            console.log('Received average:', average, "ambientNoiseLevelRef.current:", ambientNoiseLevelRef.current, "noisemultiplierRef.current:", noisemultiplierRef.current);
            if (silenceTimeoutRef.current) {
              clearTimeout(silenceTimeoutRef.current);
              silenceTimeoutRef.current = null;
            }
            if (!isSpeakingRef.current) {
              setIsSpeaking(true);
              isSpeakingRef.current = true; // Update the ref  
              audioChunksRef.current = []; // Reset the ref
              setAudioChunks([]); // Reset the state
              // startRecording();
            }
          } else {
            if (!silenceTimeoutRef.current) {
              silenceTimeoutRef.current = setTimeout(() => {
                setIsSpeaking(false);
                isSpeakingRef.current = false; // Update the ref
                // stopRecording();
                console.log('audio', audioChunksRef.current);
                if(audioChunksRef.current.length>2){
                  sendAudioToServer(audioChunksRef.current)
                }
              }, pauseTime); // Adjust the timeout duration as needed
            }
          }
        }
      };

      setIsAudioInitialized(true);
      startCalibration();
    } catch (err) {
      console.error('Error accessing microphone', err);
    }
  };

  const startCalibration = () => {
    setIsCalibrating(true);
    isCalibratingRef.current = true; // Update the ref
    let countdown = 3;
    const interval = setInterval(() => {
      setCalibrationCountdown(countdown);
      countdown -= 1;
      if (countdown < 0) {
        clearInterval(interval);
        finishCalibration();
        startRecording();
      }
    }, 1000);
  };

  const finishCalibration = () => {
    setIsCalibrating(false);
    isCalibratingRef.current = false; // Update the ref
    console.log(calibrationDataRef.current.length)
    if (calibrationDataRef.current.length > 0) {
      // Calculate the mean
      const mean = calibrationDataRef.current.reduce((a, b) => a + b, 0) / calibrationDataRef.current.length;

      // Calculate the variance
      const variance = calibrationDataRef.current.reduce((a, b) => a + Math.pow(b - mean, 2), 0) / calibrationDataRef.current.length;

      // Calculate the standard deviation
      const stdDev = Math.sqrt(variance);

      // Filter out the outliers
      const filteredData = calibrationDataRef.current.filter(value => Math.abs(value - mean) <= stdDev);

      // Calculate the average of the filtered data
      const averageNoiseLevel = filteredData.reduce((a, b) => a + b, 0) / filteredData.length;

      ambientNoiseLevelRef.current = averageNoiseLevel; // Update the ref
      console.log('Calibration complete. Ambient noise level (average after removing outliers): ', averageNoiseLevel);
    } else {
      ambientNoiseLevelRef.current = 0.00098;
      console.error('Calibration data is empty. Unable to determine ambient noise level.');
    }
  };

  const handleStart = () => {
    if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
      audioContextRef.current.resume();
    } else {
      initAudio();
    }
  };

  const handleStop = () => {
    console.log("stop");
    if (audioWorkletNodeRef.current) {
      audioWorkletNodeRef.current.disconnect();
      audioWorkletNodeRef.current = null;
    }
    if (analyserRef.current) {
      analyserRef.current.disconnect();
      analyserRef.current = null;
    }
    if (microphoneRef.current) {
      microphoneRef.current.disconnect();
      microphoneRef.current = null;
    }
    if (audioContextRef.current) {
      audioContextRef.current.close();
      audioContextRef.current = null;
    }
    if (silenceTimeoutRef.current) {
      clearTimeout(silenceTimeoutRef.current);
      silenceTimeoutRef.current = null;
    }
    setIsAudioInitialized(false);
    setIsSpeaking(false);
    isSpeakingRef.current = false; // Reset the ref
    setIsCalibrating(false);
    setCalibrationCountdown(3);
    calibrationDataRef.current = [];
    isCalibratingRef.current = false; // Reset the ref
    ambientNoiseLevelRef.current = 0; // Reset the ref
  };

  const startRecording = () => {
    console.log("start Recording");
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then(stream => {
        const options = {mimeType:'audio/webm'};
        const mediaRecorder = new MediaRecorder(stream, options);
        
        // Start the media recorder and set the interval for dataavailable event to 1 second
        mediaRecorder.start(1000);
  
        // Event handler for when data is available
        mediaRecorder.ondataavailable = handleDataAvailable;
  
        // Event handler for when recording stops
        mediaRecorder.onstop = () => {
          // No need for interval clearing, as we use mediaRecorder's built-in functionality
          console.log("Recording stopped");
        };
      })
      .catch(err => {
        console.error('Error accessing microphone:', err);
      });
  };
  
  const handleDataAvailable = (event) => {
    if (event.data.size > 0) {
      audioChunksRef.current.push(event.data); // Update the ref
      setAudioChunks([...audioChunksRef.current]); // Update the state
    }
  };
  

  const stopRecording = () => {
    mediaRecorder.stop();
    console.log("stop");
  };


  const playAudio = (audioChunks) => {
    // console.log("playing");
    setPlaying(true);
    const audioBlob = new Blob(audioChunks, { type: "audio/wav" });
    const audioUrl = URL.createObjectURL(audioBlob);
    const audio = new Audio(audioUrl);
    audio.play();
    audio.onended = () => {
      setPlaying(false)
    };
  };

  const textToSpeech = async (audioData) => {
    try {
      const audioBase64 = audioData;
      const audioUrl = `data:audio/mpeg;base64,${audioBase64}`;
      const audio = new Audio(audioUrl);
      audioRef.current = audio; // Store the audio element in the ref

      // Add an event listener for the 'ended' event
      audio.addEventListener('ended', () => {
        console.log('Speech playback completed');
        setResponses(prevResponses => {
          const updatedResponses = [...prevResponses];
          updatedResponses.shift(); // Remove the first element
          if (updatedResponses.length > 0) {
            textToSpeech(updatedResponses[0].audio_base64); // Play the next audio
          } else {
            audioRef.current = false;
          }
          return updatedResponses;
        });
      });

      audio.play();
      setIsMuted((mute) => {
        audioRef.current.muted = mute;
        return mute;
      })

    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // console.log("speech use effect",!audioRef.current," ",responses.length);
    // if (responses.length > 0 && !audioRef.current) {
    //   textToSpeech(responses[0].audio_base64); // Start playing the first audio
    // }
  }, [responses]);

  const createID = async () => {
    const requestData = {
      userid: "-1",
      usertype: "test",
      batchid: "test",
      roleplay_situation_uid: "doctor_mr"
    };
  
    try {
      const response = await fetch(apiUrl + 'start', {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify(requestData)
      });
  
      if (!response.ok) {
        throw new Error(`Failed to fetch response from server: ${response.statusText}`);
      }
  
      const data = await response.json();
      setResponses(prevResponses => [...prevResponses, data]); // Append new response to the array
      console.log(data);
      localStorage.setItem('ClientId', data.id);
    } catch (error) {
      console.error("Error sending data to server:", error);
    }
  };

  const sendAudioToServer = async (audiodata) => {
    // console.log(audiodata)

    var storedVariable = localStorage.getItem('ClientId');

    // if (audiodata != null && audiodata.length > 0 && storedVariable == null) {
    //   const formData = new FormData();
    //   formData.append("userid", "-1");
    //   formData.append("usertype", "test");
    //   formData.append("batchid", "test");
    //   formData.append("roleplay_situation_uid", "doctor_mr");
    //   formData.append('audio', new Blob(audiodata, { type: 'audio/wav' }));

    //   try {
    //     const response = await fetch(apiUrl, {
    //       method: "POST",
    //       body: formData,
    //     });

    //     if (!response.ok) {
    //       throw new Error("Failed to fetch response from server");
    //     }

    //     const data = await response.json();
    //     setResponses(prevResponses => [...prevResponses, data]); // Append new response to the array
    //     console.log(data);
    //     localStorage.setItem('ClientId', data.id);
    //   } catch (error) {
    //     console.error("Error sending audio to server:", error);
    //   }
    // } else 
    console.log(audiodata,audiodata.length,storedVariable)
    if (audiodata != null && audiodata.length > 0 && storedVariable != null) {
      const formData = new FormData();
      formData.append("id", storedVariable);
      formData.append('audio', new Blob(audiodata, { type: 'audio/wav' }));

      try {
        const response = await fetch(apiUrl+'process', {
          method: "POST",
          body: formData,
        });

        if (!response.ok) {
          throw new Error("Failed to fetch response from server");
        }

        const data = await response.json();
        setResponses(prevResponses => [...prevResponses, data]); // Append new response to the array
        console.log(data);
        localStorage.setItem('ClientId', data.id);
      } catch (error) {
        console.error("Error sending audio to server:", error);
      }
    }
    setPaused(false)
    audioChunksRef.current = []; // Reset the ref
    setAudioChunks([]); // Reset the state
  };

  useEffect(() => {
    if (audioChunks[0] && audioChunks[0].size > 25000 && calibrationCountdown < 1 && isRecording) {
      sendAudioToServer(audioChunks)
      // playAudio(audioChunks);
      setPaused(true)
    }
  }, [audioChunks])

  useEffect(() => {
    let timer;
    if (isRecording) {
      timer = setInterval(() => {
        setSeconds(prevSeconds => prevSeconds + 1);
      }, 1000);
    } else {
      clearInterval(timer);
    }

    return () => clearInterval(timer);
  }, [isRecording]);

  useEffect(() => {
    // console.log("Noise multiplier updated:", noisemultiplier);
    noisemultiplierRef.current = noisemultiplier; // Update the ref whenever the state changes
  }, [noisemultiplier]);

  useEffect(() => {
    isSpeakingRef.current = isSpeaking; // Update the ref whenever the state changes
    // console.log("speaking ",isSpeaking);
  }, [isSpeaking]);

  const handleClickStart = () => {
    var storedVariable = localStorage.getItem('ClientId');
    if (storedVariable != null) {
      checkPrevClientId(storedVariable);
      setShowAksPrev(true);
    } else {
      createID();
      setIsRecording(true);
      handleStart();
    }

    setSeconds(0);
    audioChunksRef.current = []; // Reset the ref
    setAudioChunks([]); // Reset the state
  };

  const handleClickStop = () => {
    if (calibrationCountdown > 0) return;
    stopRecording();
    setIsRecording(false);
    handleStop();

    // Stop the audio playback if it's currently playing
    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
      audioRef.current = null;
    }

    // Clear the responses
    setResponses([]);
  };

  const handleClickReset = () => {
    setResponses([]); // Reset responses
    if (audioContextRef.current && audioContextRef.current.state === 'suspended') {
      audioContextRef.current.resume();
    } else {
      initAudio();
    }

    setSeconds(0);
    setIsRecording(true);
    audioChunksRef.current = []; // Reset the ref
    setAudioChunks([]); // Reset the state
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const remainingSeconds = time % 60;
    return `${minutes}:${remainingSeconds < 10 ? "0" : ""}${remainingSeconds}`;
  };

  const handleExit = () => {
    // Implement exit logic if needed
  };

  const handleMuteToggle = () => {
    console.log('called', isMuted)
    if (isRecording) {
      if (audioRef.current) {
        audioRef.current.muted = !isMuted;
      }
      setIsMuted(!isMuted);
    }
  };

  useEffect(() => {
    return () => {
      handleStop();
    };
  }, []);
  return (
    <div className="flex justify-center h-screen pt-10 w-full space-y-8">
      <div className="h-[80vh] w-100 h-100 rounded-lg p-5 relative">
        {playing && <div>Playing</div>}
        {isRecording &&
          <div className="absolute top-2 right-2" onClick={()=>handleMuteToggle()}>
            <img
              src={isMuted ? UnmuteIcon : MuteIcon}
              alt="Mute/Unmute"
              className="w-8 h-8 cursor-pointer"
            />
          </div>
        }
        <div className="h-[40vh] flex items-center justify-center relative flex-col">
          {calibrationCountdown < 1 && isRecording && <span className="animate-pulse text-center text-xl font-bold text-darkslategray">Listening ...</span>}
          {calibrationCountdown > 0 && isRecording && <div className="text-xl">{calibrationCountdown}</div>}
          {paused && isRecording && <span className="text-gray-500">Generating Response</span>}
          {responses.length > 0 && isRecording && (
            <div className="mt-4 max-h-48 overflow-y-auto border border-gray-300 p-2 z-10">
              {responses.map((response, index) => (
                <div key={index}>{JSON.stringify(response.result)}</div>
              ))}
            </div>
          )}
        </div>

        <div className="flex justify-center w-full">
          {isRecording && (
            <div className="rounded-2xl text-darkgray text-center p-2 flex flex-col items-center space-y-2 mt-20">
              <div className="w-full flex flex-col items-center">
                <label htmlFor="noisemultiplier" className="text-sm font-bold text-darkgray mb-2">Noise Multiplier: {noisemultiplier}</label>
                <input
                  id="noisemultiplier"
                  type="range"
                  min="1"
                  max="120"
                  value={noisemultiplier}
                  onChange={(e) => setNoisemultiplier(e.target.value)}
                  className="w-full"
                />
              </div>
              <div className="w-full flex flex-col items-center">
                <label htmlFor="pauseTime" className="text-sm font-bold text-darkgray mb-2">Pause Time: {pauseTime} ms</label>
                <input
                  id="pauseTime"
                  type="range"
                  min="200"
                  max="3000"
                  value={pauseTime}
                  onChange={(e) => setPauseTime(e.target.value)}
                  className="w-full"
                />
              </div>
              <div className="rounded-[30px] bg-green1 text-darkgray text-center h-12 flex flex-col justify-center items-center space-y-2 cursor-pointer mt-4" onClick={handleClickStop}>
                <p className="flex items-center text-sm font-bold text-white px-12 font-sans">End Conversation</p>
              </div>
              <p className="text-orange-500 font-sans">{formatTime(seconds)}</p>
            </div>
          )}
          {!isRecording && !showAskPrev && seconds === 0 && (
            <div className="rounded-2xl text-darkBlue2 text-center p-2 flex flex-col items-center space-y-2">
              <img src={StartRecording} alt="StartRecording" className="w-24 cursor-pointer" onClick={handleClickStart} />
              <p className="font-sans text-lg font-bold text-darkBlue2 mt-2"> Start Taking </p>
            </div>
          )}

          {!isRecording && showAskPrev && prevClientID && (
            <div className="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center items-center">
              <div className="bg-white p-5 rounded-lg text-center max-w-xs">
                <div className="font-bold">
                  Do you want to continue with your previous Conversation?
                </div>
                <div className="mt-2 flex items-center justify-evenly">
                  <div className="cursor-pointer mt-2 w-fit px-4 py-2 rounded bg-customRed text-white" onClick={() => {
                    setIsRecording(true);
                    setShowAksPrev(false);
                    handleStart();
                  }}>
                    YES
                  </div>
                  <div className="cursor-pointer mt-2 w-fit px-4 py-2 rounded bg-customRed text-white" onClick={() => {
                    setIsRecording(true);
                    checkPrevClientId('');
                    localStorage.removeItem('ClientId');
                    createID();
                    setShowAksPrev(false);
                    handleStart();
                  }}>
                    NO
                  </div>
                </div>
              </div>
            </div>
          )}

          {!isRecording && !showAskPrev && seconds > 0 && (
            <div className="space-x-4 mt-12">
              <div className="flex items-center">
                <div className="flex flex-col items-center">
                  <img src={ResetRecordingIcon} alt="ResetRecording" className="w-15 h-14 cursor-pointer" onClick={handleClickReset} />
                </div>
                <p className="font-sans text-lg font-bold text-darkBlue2 ml-4"> Start Again </p>
              </div>

              <div className="rounded-[30px] bg-orange-500 text-darkgray text-center h-12 flex flex-col justify-center items-center space-y-2 mt-12">
                <p className="flex items-center text-sm font-bold text-white px-12 font-sans" onClick={handleExit}>Exit</p>
              </div>
            </div>
          )}
        </div>
      </div>
      {isRecording && <div className="absolute h-80 w-96 rounded-2xl overflow-hidden">
        <Waves className="w-full" />
      </div>}
    </div>
  );
};

export default AiRoleplay;