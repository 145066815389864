import React, { useState, useEffect } from "react";
import ImageIconButton from "../../../components/ImageIconButton";
import Heading from "../../../components/Heading";
import Title from "../../../components/Title";
import Text from "../../../components/Text";

import { getDCJobScenarios } from "../../../api/services/multispeakerTranscribe";

function Situations(props) {
  const { userId } = props;
  const [dCJobScenarioData, setDCJobScenarioData] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dCJobScenarioData = await getDCJobScenarios(userId);
        setDCJobScenarioData(dCJobScenarioData.scenarios);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div>
      <div className="text-white px-6 py-8">
        <ImageIconButton
          src={require("../../../assets/icons/arrow_back.png")}
          onClick={() => props.setSelectedWidget(null)}
        />
        <Heading title="Situations" />
        <div className="my-4 overflow-scroll h-[600px]">
          {dCJobScenarioData &&
            dCJobScenarioData.map((scenario) => (
              <div className="mb-6">
                <div className="bg-[#171C29] px-8 py-4 border-b-[1px] border-[#70737D]">
                  <Title label="Title" color="secondary" />
                  <Text color="lightgray" label={scenario.title} />
                </div>
                <div className="bg-[#171C29] px-8 py-4 border-b-[1px] border-[#70737D]">
                  <Title label="Audiences" color="secondary" />
                  <Text color="lightgray" label={scenario.audiences} />
                </div>
                <div className="bg-[#171C29] px-8 py-4">
                  <Title label="Scenario" color="secondary" />
                  <Text color="lightgray" label={scenario.scenario} />
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

export default Situations;
