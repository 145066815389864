import starbadge from "../assets/icons/starbadge.svg";
import { useNavigate } from "react-router-dom";

const Welldone = () => {
  const navigate = useNavigate();
  const params = new URLSearchParams(window.location.search);
  const userId = params.get("userId");
  const email = params.get("email");
  const assessmentId = params.get("assessmentId");
  const flow = params.get("flow");
  localStorage.setItem("email", email);
  localStorage.setItem("userId", userId);
  localStorage.setItem("assessmentId", assessmentId);
  localStorage.setItem("flow", flow);

  const handleContinue = () => {
    navigate("/evaluate/aifeedback");
  };

  return (
    <div className="flex justify-center h-dvh w-full font-sans">
      <div className="h-full w-full rounded-lg p-12 flex flex-col justify-between">
        <div className="h-[75vh] w-full flex justify-center flex-col items-center">
          <div>
            <img
              src={starbadge}
              className="h-[12rem] w-auto rounded-lg mb-4"
              alt="Blub"
            />
          </div>
          <div className="text-darkgray font-[800] text-[2.1rem]">
            Well Done!
          </div>
          <div className="text-gray-600 text-[1.2rem] text-center mt-1">
            Great Job on your Practice
          </div>
        </div>
        <div className="flex justify-center absolute left-0 right-0 bottom-[32px] font-sans w-full px-10">
          <button
            className="tracking-wider w-full bg-orange1 min-h-14 text-white text-base font-sans font-semibold py-4 px-6 rounded-full flex justify-center items-center"
            onClick={handleContinue}
          >
            Learn More{" "}
            <img
              alt="right-arrow"
              src={require("../assets/icons/arrow-right-white.png")}
              className="w-5 h-5 ml-2 contain"
            />
          </button>
        </div>
      </div>
    </div>
  );
};

export default Welldone;
